import callIcon from "../../components/images/icons/call.png";
import emailIcon from "../../components/images/icons/email.png";
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Popup from "../Ui-Elements/PopUp";

const ContactUs = () => {
  const initialFormData = {
    name: "",
    email: "",
    message: "",
    mobileNumber: "",
    // captcha: "",
    status: "PENDING",
    method: "Email",
    mode: "Web",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [captchaVarification, setCaptchaVarification] = useState("");

  function generateCaptcha() {
    const characters = "abcdefghijklmnopqrstuvwxyz1234567890";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captcha === captchaVarification) {
      try {
        const response = await axios.post(
          "https://prod-api.rasoda.org/chef/v1/admin/addContactUs",
          formData
        );
        handleClick();
        setFormData(initialFormData);
        setCaptcha(generateCaptcha());
        // alert(response.data.message);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      alert("Captcha verification failed. Please try again.");
    }
  };

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSuccess(true);
      setIsPopupVisible(true);
    }, 3000);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };
  return (
    <div className="container mx-auto mt-10">
      <div class="mt-8 md:mt-12">
        <h1 class="text-center font-bold text-2xl md:text-3xl">
          Get In Touch!
        </h1>
        <p class="text-center mt-2 text-sm md:text-lg font-normal">
          Contact us for any queries for discussion.
        </p>
      </div>
      <div class="flex flex-col items-center md:flex-row justify-center md:space-x-10 p-5 md:p-10">
        <div class="hover:bg-white p-3 text-center md:text-left">
          <img src={callIcon} class="h-6 w-6 ml-10" alt="image not found" />
          <p>+91 9179019103</p>
        </div>
        <div class="hover:bg-white p-3 mt-4 md:mt-0 text-center md:text-left">
          <img src={emailIcon} class="h-6 w-6 ml-16" alt="image not found" />
          <p>info@cosmostaker.com</p>
        </div>
      </div>
      <div class="contact-form">
        <form onSubmit={handleSubmit}>
          <div class="flex flex-col lg:gap-5 sm:gap-0 md:flex-row md:space-x-10 mr-5 md:mx-10">
            <div class="w-full m-2">
              <div class="pt-4">
                <label for="name" class="text-black">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="h-11 w-full input rounded-lg pl-2"
                  required
                  onChange={handleChange}
                  value={formData.name}
                />
              </div>
              <div class="pt-4">
                <label for="mail" class="text-black">
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="subject"
                  class="h-11 w-full input rounded-lg pl-2"
                  required
                  onChange={handleChange}
                  value={formData.email}
                />
              </div>
              <div class="pt-4">
                <label for="phone" class="text-black">
                  Your Phone
                </label>
                <input
                  type="text"
                  // pattern="[6789][0-9]{9}"
                  class="h-11 w-full input rounded-lg pl-2"
                  name="mobileNumber"
                  id="subject"
                  required
                  onChange={handleChange}
                  value={formData.mobileNumber}
                />
              </div>
            </div>

            <div class="w-full m-2 pt-4">
              <label for="message" class="text-black">
                Message
              </label>
              <textarea
                class="w-full h-52 input pl-2 pt-2"
                name="message"
                rows="5"
                required
                onChange={handleChange}
                value={formData.message}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-center lg:gap-5 md:gap-5 sm:gap-0 flex-wrap mt-4">
            <div className="h-11 px-20 justify-center text-lg bg-gray-300 rounded-lg pl-2">
              <h4 id="captcha" className="mt-2 absolute ml-5">
                {captcha}
              </h4>
            </div>
            <div>
              <input
                type="text"
                id="inputType"
                className=" h-11 w-28 input rounded-lg pl-2"
                placeholder="Enter Captcha"
                name="captcha"
                onChange={(e) => setCaptchaVarification(e.target.value)}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="flex flex-1 justify-center items-center mt-5 md:mt-4 ">
            <button
              className="sell-btn-3 rounded-lg"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-[102px] h-6 mr-2  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Send Message"
              )}
            </button>
            <Popup
              message="Record created successfully"
              show={isPopupVisible}
              onClose={closePopup}
              success={success}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
