import Navbar from "../../Navbar/secondNav";
import Footer from "../footer";
import PageTop from "../../Ui-Elements/pageTopArrow";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <section className="shadow-3xl p-[20px] mt-10 rounded-[40px]  mb-10 custom-container">
        <section>
          <div className="container mx-auto">
            <h1 className="text-center text-5xl font-bold">Privacy Policy</h1>
            <p className="font-bold mt-10">Last Updated: June, 2024</p>
            <p className="text-black">
              PS: Rasodaa is a product of CosmostakeR (
              <a
                href="http://www.cosmostaker.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                cosmostaker.com
              </a>
              ), having its principal office in Indore, all the references made
              to Rasodaa in this document can be applied to CosmostakeR.
            </p>
            <p className="text-black">
              CosmostakeR and/or its affiliates (termed as "Rasodaa," the
              "Company," "we," "us," and "our,") respect your privacy and are
              committed to protecting it through its compliance with its privacy
              policies. This policy describes:
            </p>
            <p className="text-black">
              the types of information that Rasodaa may collect from you when
              you access or use its websites, applications and other online
              services (collectively, referred as "Services"); and its practices
              for collecting, using, maintaining, protecting and disclosing that
              information. This policy applies only to the information Rasodaa
              collects through its Services, in email, text and other electronic
              communications sent through or in connection with its Services.
            </p>
            <p className="text-black">
              This policy <strong>DOES NOT</strong> apply to information that
              you provide to, or that is collected by, any third-party, such as
              chefs at which you pay through Rasodaa's Services and social
              networks that you use in connection with its Services. Rasodaa
              encourages you to consult directly with such third-parties about
              their privacy practices.
            </p>
            <p>
              Please read this policy carefully to understand Rasodaa's policies
              and practices regarding your information and how Rasodaa will
              treat it. By accessing or using its Services and/or registering
              for an account with Rasodaa, you agree to this privacy policy and
              you are consenting to Rasodaa’s collection, use, disclosure,
              retention, and protection of your personal information as
              described here. If you do not provide the information Rasodaa
              requires, Rasodaa may not be able to provide all of its Services
              to you.
            </p>
            <p>
              If you reside in India, CosmostakeR, located at Third Floor, 304
              Sai Ram Plaza, Mangal Nagar, Near Rajiv Gandhi Square, Indore -
              483504, Indore, India will be the controller of your personal data
              provided to, or collected by or for, or processed in connection
              with our Services.
            </p>
            <p>
              Your data controller is responsible for the collection, use,
              disclosure, retention, and protection of your personal information
              in accordance with its privacy standards as well as any applicable
              national laws. Your data controller may transfer data to other
              members of Rasodaa as described in this Privacy Policy. Rasodaa
              may process and retain your personal information on its servers in
              India where its data centres are located, and/or on the servers of
              its third parties (in or outside India), having contractual
              relationships with Rasodaa.
            </p>
            <p>
              This policy may change from time to time, your continued use of
              Rasodaa’s Services after it makes any change is deemed to be
              acceptance of those changes, so please check the policy
              periodically for updates.
            </p>
            <h1 className="text-center px-10 py-10 text-3xl">
              The information we collect and how we use it
            </h1>
            <p>
              CosmostakeR ("Rasodaa," the "Company," "we," "us," and "our”)
              collects several types of information from and about users of our
              Services, including:
            </p>
            <ul className="ml-10" style={{ listStyleType: "disc" }}>
              <li>
                Your Personal Information("PI") - Personal Information is the
                information that can be associated with a specific person and
                could be used to identify that specific person whether from that
                data, or from the data and other information that we have, or is
                likely to have access to. We do not consider personal
                information to include information that has been made aggregated
                or anonymous so that it can no longer be used to identify a
                specific person, whether in combination with other information
                or otherwise.
              </li>
              <li>
                Information about your internet connection, the equipment you
                use to access our Services and your usage details.
              </li>
            </ul>
            <br />
            <strong>We collect this information:</strong>
            <ul className="ml-10" style={{ listStyleType: "disc" }}>
              <li>Directly from you when you provide it to us; and/or</li>
              <li>
                Automatically as you navigate through our Services (information
                collected automatically may include IP addresses, usage details
                and information collected through cookies, web beacons and other
                tracking technologies).
              </li>
            </ul>
            <br />
            <strong>Information You Provide to Us</strong>
            <p>
              The information we collect on or through our Services may include:
            </p>
            <ul className="ml-10" style={{ listStyleType: "disc" }}>
              <li>
                Your account information: Your full name, email address,
                password, postal code and other information you may provide with
                your account, such as your mobile phone number, website and
                gender. Your profile picture that will be publicly displayed as
                part of your account profile. You may optionally provide us with
                this information through third-party sign-in services such as
                Facebook and Google Plus. In such cases, we fetch and store
                whatever information is made available to us by you through
                these sign-in services.
              </li>
              <li>
                Your preferences: Your settings and preferences such as language
                and time zone.
              </li>
              <li>
                Your content: Information you provide through our Services,
                including your reviews, photographs, comments, lists, followers,
                the users you follow, food ordering details and history, contact
                information, favorite chefs of people you add to, or notify of,
                names, and other information you provide on our Services, and
                other information in your account profile.
              </li>
              <li>
                Your searches and other activities: The search terms you have
                looked up and results you selected.
              </li>
              <li>
                Your browsing information: How long you used our Services and
                which features you used; the ads you clicked on.
              </li>
              <li>
                Your communications: Communications between you and other users
                or merchants through our Services; your participation in a
                sweepstakes, contest survey, poll or promotion scheme; your
                request for certain features (e.g., newsletters, updates or
                other products); your communication with us about employment
                opportunities posted to the services.
              </li>
              <li>
                Your transactional information: If you make purchases or
                reservations through our Services, we may collect and store
                information about you to process your requests and automatically
                complete forms for future transactions, including (but not
                limited to) your phone number, address, email, credit or payment
                card information and billing information. This information may
                be shared with third-parties which assist in processing and
                fulfilling your requests, including PCI compliant payment
                gateway processors. If you write reviews about businesses with
                which you conduct transactions through our Services, we may
                publicly display information that you transacted with those
                businesses.
              </li>
              <li>
                Your Public Posts: You also may provide information (such as
                ratings, reviews, tips, photos, comments, likes, bookmarks,
                friends, lists, etc.) to be published or displayed (hereinafter,
                "posted") on publicly accessible areas of our Services, or
                transmitted to other users of our Services or third-parties
                (collectively, "User Contributions"). Your User Contributions
                are posted on and transmitted to others at your own risk.
              </li>
              <li>
                Although we limit access to certain pages, you may set certain
                privacy settings for such information by logging into your
                account profile. Please be aware that no security measures are
                perfect or impenetrable (see "Security" section below).
                Additionally, we cannot control the actions of other users of
                our Services with whom you may choose to share your User
                Contributions. Therefore, we cannot and do not guarantee that
                your User Contributions will not be viewed by unauthorized
                persons. We may display this information on the Services, share
                it with businesses, and further distribute it to a wider
                audience through third-party sites and services. You should be
                careful about revealing any sensitive details about yourself in
                such postings.
              </li>
            </ul>
            <p>
              We use the information you provide to us to enhance the
              functionality and improve the quality of our Services, and to
              personalize your experience while using our Services. We also use
              this information to display relevant advertising, provide support
              to you, communicate with you, and comply with our legal
              obligations.
            </p>

            <strong>Information about Your Messages</strong>

            <p>
              If you exchange messages with others through the Services, we may
              store them in order to process and deliver them, allow you to
              manage them, and investigate possible violations of our Terms of
              Service and wrongdoing in connection with the Services. If you
              send information from the Services to your mobile device via SMS
              text message, we may log your phone number, phone carrier, and the
              date and time that the message was processed. Carriers may charge
              recipients for texts that they receive.
            </p>

            <strong>
              Information We Collect Through Automatic Data Collection
              Technologies
            </strong>

            <p>
              We may automatically collect certain information about the
              computer or devices (including mobile devices) you use to access
              the Services, and about your use of the Services, even if you use
              the Services without registering or logging in.
            </p>

            <ul className="ml-10" style={{ listStyleType: "disc" }}>
              <li>
                Usage information: Details of your use of our Services,
                including location data, logs, traffic data, and other
                communication data and the resources that you access and use on
                or through our Services.
              </li>
              <li>
                Stored information and files: Our applications also may access
                metadata and other information associated with other files
                stored on your mobile device. This may include, for example,
                personal contacts, photographs, audio and video clips, and
                address book information.
              </li>
              <li>
                Computer and device information: Information about your
                computer, Internet connection, and mobile device, including your
                IP address, operating systems, platforms, browser type, other
                browsing information like (connection, speed, connection type,
                etc.), device type, device's unique device identifier, mobile
                network data, and the device's telephone number.
              </li>
              <li>
                Location information: Our applications collect real-time
                information about the location of your device, as permitted by
                you.
              </li>
              <li>
                Last URL visited: The URL of the last web page you visited
                before visiting our websites.
              </li>
              <li>
                Mobile device IDs: Unique mobile device identifier (e.g. IDFA or
                other device IDs on Apple devices like the iPhone and iPad), if
                you're using our Services on a mobile device, we may use mobile
                device IDs (the unique identifier assigned to a device by the
                manufacturer), instead of cookies, to recognize you.
              </li>
              <li>
                Your preferences: Your preferences and settings such as time
                zone and language.
              </li>
              <li>
                Your activity on the Services: Information about your activity
                on the Services, such as your search queries, comments, domain
                names, search results selected, number of clicks, pages viewed
                and the order of those pages, how long you visited our Services,
                the date and time you used the Services, error logs, and other
                similar information.
              </li>
              <li>
                Mobile status: For mobile application users, the online or
                offline status of your application.
              </li>
              <li>
                Applications: If you use the Rasodaa application, Rasodaa may
                collect information about the presence and/or absence and/or
                details pertaining to other applications on your mobile phone.
              </li>
            </ul>

            <strong>Precise Location Information and How to Opt Out</strong>

            <p>
              We may collect and process information about your mobile device's
              GPS location (including the latitude, longitude, or altitude of
              your mobile device) and the time the location information is
              recorded when you use one of our location-enabled services to
              customize the Services with location-based information and
              features. Some of these services require your personal data in
              order for the feature to function, and we may link location data
              to your device ID and other information we have on file for you.
              We keep this information for no longer than is reasonably
              necessary to provide you with services. If you want to use the
              specific feature, you will be asked to consent to the use of your
              data for this purpose. You can withdraw your consent at any time
              by disabling your device's GPS or other location-tracking
              functions if your device allows it.
            </p>

            <strong>Cookies and Other Electronic Tools</strong>

            <p>
              Cookies, pixel tags, web beacons, mobile device IDs, "flash
              cookies," and similar files or technologies may be used by us and
              third-party partners to collect and store information about your
              use of the Services and third-party websites. A cookie is a small
              text file stored on your computer that allows us to recognize you
              when you visit our website, store your preferences and settings,
              improve your experience by delivering content and advertising
              tailored to your interests, perform research and analytics, track
              your use of our Services, and assist with security and
              administrative functions. Cookies can be persistent or only stored
              for the duration of a single session.
            </p>

            <p>
              Most browsers are set to automatically allow cookies. Please note
              it may be possible to disable some (but not all) cookies through
              your device or browser settings, but doing so may interfere with
              certain functionality on the Services. Major browsers provide
              users with various options when it comes to cookies. Users can
              usually set their browsers to block all third-party cookies, block
              all cookies, or block specific cookies. To change your cookie
              settings, go to your browser's help page. You will need to opt out
              on each browser and device you use.
            </p>

            <p>
              Third parties, including social media services, whose products or
              services are accessible or advertised through the Services, may
              also use cookies or similar tools, and we recommend that you
              review their privacy policies for information about their cookies
              and other practices. We have no control over such partners'
              practices, and their privacy policies govern their interactions
              with you.
            </p>

            <strong>Information from Third Parties</strong>

            <p>
              We may collect, process, and store your user ID associated with
              any social media account that you use to sign into, connect to, or
              use the Services. When you sign in to your account using your
              social media account information, or otherwise connect to your
              social media account with the Services, you consent to our
              collection, storage, and use of the information you make available
              to us through the social media interface in accordance with this
              Privacy Policy. This includes, but is not limited to, any
              information you have made public through your social media
              account, information shared with us by the social media service,
              or information disclosed during the sign-in process.
            </p>

            <strong>Anonymous or De-Identified Data</strong>

            <p>
              We may anonymize and/or de-identify information collected from you
              through the Services or via other means. As a result, our use and
              disclosure of aggregated and/or de-identified information is not
              restricted by this Privacy Policy and may be used and disclosed to
              others without limitation.
            </p>

            <strong>How we use the information we collect</strong>

            <ul className="ml-10" style={{ listStyleType: "disc" }}>
              <li>Process and respond to your queries</li>
              <li>
                Understand our users and improve the content and features of our
                Services
              </li>
              <li>Administer our Services and diagnose technical problems</li>
              <li>
                Send you communications that you have requested or that may be
                of interest to you
              </li>
              <li>
                If you have registered with us, we will send you questions from
                other users that you may be able to answer
              </li>
              <li>Enable us to show you ads that are relevant to you</li>
              <li>
                Generate and review reports and data about our user base and
                Service usage patterns
              </li>
              <li>Run contests and sweepstakes</li>
              <li>Assist you with customer service</li>
              <li>Provide you with policies pertaining to your account</li>
              <li>
                Carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us
              </li>
              <li>Notify you about changes to our Services</li>
              <li>
                Allow you to participate in interactive features offered through
                our Services
              </li>
              <li>For any other purpose with your consent</li>
              <li>
                We may also use your information to contact you about our own
                and third-party goods and services that may be of interest to
                you
              </li>
            </ul>
            <div className="font-bold">
              How we share the information we collect
            </div>
            <p>
              We may disclose personal information that we collect or you
              provide, as described in this privacy policy, in the following
              ways:
            </p>
            <div className="font-bold">General Information Disclosures</div>
            <ul className="ml-10" style={{ listStyleType: "disc" }}>
              <li>
                To our subsidiaries and affiliates, which are entities under the
                ultimate parent company CosmostakeR' common ownership or
                control.
              </li>
              <li>
                To contractors, advertisers/service providers, and other
                third-party service providers that we use to support our
                business (for example, dinner reservations and food delivery)
                and who are contractually obligated to keep personal information
                confidential and use it only for the purposes for which we
                disclose it to them.
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganisation, dissolution, or
                other sale or transfer of some or all of Rasodaa's assets,
                whether as a going concern or as part of a bankruptcy,
                liquidation, or similar proceeding, in which personal
                information about users of our Services is among the assets sold
                or transferred.
              </li>
              <li>
                If you have agreed to receive promotional updates, third-parties
                may market their products or services to you. We contractually
                require these third-party service providers to keep personal
                information confidential and to use it only for the purposes for
                which we provide it to them.
              </li>
              <li>To serve the purpose for which you have provided it.</li>
              <li>
                For any other reason that we disclose when you provide the
                information.
              </li>
              <li>
                Providers of Services - We may share your information with
                third-party vendors for a variety of purposes, including sending
                you communications via email, text message, or phone call to
                inform you about our products that may be of interest to you,
                pushing notifications to your mobile device on our behalf,
                providing voice recognition services to process your spoken
                queries, and so on.
              </li>
              <li>
                Legal Purposes - We may share your information when we believe
                in good faith that such sharing is reasonably necessary in order
                to investigate, prevent, or take action regarding possible
                illegal activities or to comply with legal process. We may also
                share your information to investigate and address threats or
                potential threats to the physical safety of any person, to
                investigate and address violations of this Privacy Policy or the
                Terms of Service, or to investigate and address violations of
                the rights of third parties and/or to protect the rights,
                property, and safety of Rasodaa, our employees, users, or the
                public. This may involve the sharing of your information with
                law enforcement, government agencies, courts, and/or other
                organizations on account of legal requests such as subpoena,
                court order or government demand to comply with the law.
              </li>
              <li>
                Social Networks - If you interact with social media features on
                our Services, such as the Facebook Like button, or use your
                social media credentials to log in or post content, these
                features may collect and post information about your activities
                on the social media service. The privacy policies of social
                media companies govern your interactions with them.
              </li>
              <li>
                To enforce or apply our Terms of Service and other agreements,
                such as billing and collection.
              </li>
              <li>
                If we believe that disclosure is required or appropriate to
                protect Rasodaa's, our customers', or others' rights, property,
                or safety. This includes exchanging information with other
                companies and organizations for the purposes of preventing fraud
                and lowering credit risk.
              </li>
              <li>
                Consent. We may share your information in any other
                circumstances where we have your consent.
              </li>
            </ul>
            <div className="font-bold">Information Shared with Chefs</div>
            <p>
              When you execute an online food ordering transaction through our
              Services, your information is provided to us and to the chefs with
              whom you choose to reserve. In order to facilitate your
              reservation and online food order processing, we provide your
              information to that chef. If you provide a mobile phone number,
              chefs or Rasodaa may send you text messages regarding your
              reservation or order's delivery status. When you make an online
              food ordering transaction through our Services, we may also share
              additional information with the chefs, such as information about
              your preferences and history, or information that we collect from
              third-parties.
            </p>
            <div className="font-bold">Payment Card Information</div>
            <p>
              To use certain of our Services, such as to place orders we may
              require credit or debit card account information. By submitting
              your credit or debit card account information through our
              Services, you expressly consent to the sharing of your information
              with chefs, third-party payment processors, and other third-party
              service providers (including but not limited to vendors who
              provide fraud detection services to us and other third parties),
              and you further agree to the following terms:
            </p>
            <ul className="ml-10" style={{ listStyleType: "disc" }}>
              <li>
                We provide your credit or debit card account information to our
                third-party payment service providers when you first provide it
                to us through our Services in order to use our payment services.
                These third parties may store your credit or debit card account
                information, as explained in our Terms of Use, so that you can
                use our payment services through our Services in the future.
              </li>
            </ul>
            <p>
              For information about the security of your credit or debit card
              account information, see the "Security" section below.
            </p>
            <div className="font-bold">Analytics and tailored advertising</div>
            <p>
              We may use third-party web analytics on our Services, such as
              Google Analytics, to help us better understand how you use the
              Services. These service providers employ the technology described
              in the preceding section on "Automatically-Collected Information."
              This technology will disclose or collect information directly from
              these service providers, who will use the information to evaluate
              our users' use of the Services. We also use Google Analytics, as
              described in the section below. Install the Google Analytics
              Opt-Out Browser add-on to prevent Google Analytics from collecting
              or using your information.
            </p>
            <div className="font-bold">Tailored Advertising</div>
            <p>
              Third parties whose products or services are accessible or
              advertised via the Services may also use cookies or similar
              technologies to collect information about your use of the
              Services. This is done in order to help them inform, optimize, and
              serve ads based on past visits to our website and other sites and
              report how our ad impressions, other uses of ad services, and
              interactions with these ad impressions and ad services are related
              to visits to our website. We also allow other third parties (e.g.,
              ad networks and ad servers such as Google Analytics, OpenX,
              Pubmatic, DoubleClick, and others) to serve tailored ads to you on
              the Services and to access their own cookies or similar
              technologies on your computer, mobile phone, or other device you
              use to access the Services. We have no access to, and this Privacy
              Policy does not govern, the use of cookies or other tracking
              technologies placed by such third parties. These parties may give
              you the option to opt out of ad targeting. If you want to opt out
              of receiving tailored advertising from companies that participate
              in those programs, you can visit the Network Advertising
              Initiative's Consumer Opt-Out Link and/or the Digital Advertising
              Alliance's Consumer Opt-Out Link. You can visit the Google Ads
              Settings page to opt out of Google Analytics for Display
              Advertising or to customize Google Display Network ads. Please
              note that to the extent advertising technology is integrated into
              the Services, you may still receive ads even if you opt-out of
              tailored advertising. In that case, the ads will just not be
              tailored to your interests. Also, we do not control any of the
              above opt-out links and are not responsible for any choices you
              make using these mechanisms or the continued availability or
              accuracy of these mechanisms.
            </p>
            <p>
              When using a mobile application to access the Services, you may
              also receive tailored in-app advertisements. Each operating
              system, iOS, Android, and Windows Phone, has its own set of
              instructions for preventing the delivery of tailored in-app
              advertisements. You can opt out of tailored in-app advertisements
              by reviewing the support materials and/or privacy settings for the
              respective operating systems. Please visit the privacy settings
              for the applicable device or operating system or contact the
              applicable platform operator for any other devices and/or
              operating systems.
            </p>
            <div className="font-bold">
              Choices about how we use and disclose your information
            </div>
            <p>
              We make every effort to give you options regarding the personal
              information you provide to us. You can configure your browser or
              mobile device to reject all or some browser cookies or to notify
              you when cookies are sent. To learn how to manage your Flash
              cookie settings, go to Adobe's website and navigate to the Flash
              player settings page. Please be aware that if you disable or
              refuse cookies, some parts of our Services may become inaccessible
              or cease to function properly. Your personal information is not
              shared with any advertising agency.
            </p>
            <div className="font-bold">Communication choices</div>
            <p>
              When you create an account, you agree to receive emails from other
              Rasodaa users, businesses, and Rasodaa. You can log in to manage
              your email preferences and unsubscribe from commercial email
              messages, but you cannot opt out of receiving certain
              administrative, service, and legal policies from Rasodaa.
            </p>
            <div className="font-bold">
              Reviewing, changing or deleting information
            </div>
            <p>
              If you would like to review, change, or delete personal
              information we have collected from you, or permanently delete your
              account, please use the "Contact Us" option on our website or
              mobile apps.
            </p>
            <p>
              If you delete your User Contributions from our websites, copies of
              your User Contributions may still be viewable in cached and
              archived pages, or other users of our websites may have copied or
              stored them. Our Terms of Use govern the proper access and use of
              information provided on our websites, including User
              Contributions.
            </p>
            <div className="font-bold">
              Accessing & correcting your personal information
            </div>
            <p>
              We will take reasonable steps to accurately record the personal
              information that you provide to us and any subsequent updates.
            </p>
            <p>
              We encourage you to review, update, and correct the personal
              information that we keep on file for you, and you have the right
              to request that we delete any personal information about you that
              is inaccurate, incomplete, or irrelevant for legitimate purposes,
              or that is being processed in a way that violates any applicable
              legal requirement.
            </p>
            <p>
              Your right to review, update, correct, and delete your personal
              information may be limited, subject to the law of your
              jurisdiction:
            </p>
            <ul className="ml-10" style={{ listStyleType: "disc" }}>
              <li>
                If your requests are abusive or excessive, if the rights or
                safety of another person or persons would be jeopardized, or if
                the information or material you request relates to ongoing or
                anticipated legal proceedings between you and us, or granting
                access to you would jeopardize negotiations between us or an
                investigation of possible unlawful activity. Your right to
                review, update, correct, and delete your information is governed
                by our records retention policies as well as applicable law,
                including any statutory retention requirements.
              </li>
            </ul>
            <div className="font-bold">
              Security: How we protect your information
            </div>
            <p>
              We have implemented appropriate physical, electronic, and
              managerial procedures to safeguard and help prevent unauthorized
              access to your information and to maintain data security. These
              safeguards take into account the sensitivity of the information
              that we collect, process, and store and the current state of
              technology. We follow generally accepted industry standards to
              protect the personal information submitted to us, both during
              transmission and once we receive it. The third-party service
              providers with respect to payment gateway and payment processing
              are all validated as compliant with the payment card industry
              standard (generally referred to as PCI compliant service
              providers).
            </p>
            <p>
              We accept no liability or responsibility for the disclosure of
              your information as a result of transmission errors, unauthorized
              third-party access, or other causes beyond our control. You play a
              critical role in protecting your personal information. You should
              not share your Rasodaa account's username, password, or other
              security information with anyone. We will consider you to have
              authorized the instructions if we receive them using your username
              and password.
            </p>
            <div className="font-bold">Permissible Age</div>
            <p>
              The Services are not intended for users under the age of 18,
              unless permitted under applicable local laws (Permissible Age). We
              do not knowingly collect any personal information from users or
              market to or solicit information from anyone under the Permissible
              Age. If we become aware that a person submitting personal
              information is under the Permissible Age, we will delete the
              account and any related information as soon as possible. If you
              believe we might have any information from or about a user under
              the Permissible Age, please contact us at{" "}
              <a href="mailto:info@cosmostaker.com" className="text-blue-700">
                info@cosmostaker.com
              </a>
            </p>
            <div className="font-bold">Third party links and services</div>
            <p>
              Links to third-party websites may be included in the Services.
              Depending on the feature, your use of these features may result in
              the collection, processing, or sharing of information about you.
              Please be aware that we are not responsible for the content or
              privacy practices of third-party websites or services to which our
              services may link. We do not recommend or make any claims about
              third-party websites or services. Our Privacy Policy does not
              cover the information you choose to provide to or that these third
              parties collect. We strongly advise you to read the privacy
              policies of such third parties.
            </p>
            <div className="font-bold">Job applicants</div>
            <p>
              If you submit information to us via our Service when applying for
              a position with our company, we will use that information to
              evaluate your application. We may keep your information for an
              indefinite period of time. This information may be shared with
              other companies to evaluate your qualifications for the specific
              position or other available positions, as well as with third-party
              service providers retained by us to collect, maintain, and analyze
              candidate submissions for job postings. For more information,
              please see our Privacy Policy on our careers page.
            </p>
            <div className="font-bold">Changes to this privacy policy</div>
            <p>
              We reserve the right to make changes to this Privacy Policy at any
              time to reflect changes in the law, our data collection and use
              practices, the features of our services, or technological
              advances. Please check this page for updates on a regular basis.
              The use of information collected by us is governed by the Privacy
              Policy in effect at the time the information is used. If we make
              significant changes to this Privacy Policy, we will post them
              here. Please carefully review the changes. Your continued use of
              the Services after changes to this Privacy Policy are posted
              constitutes your consent and acceptance of those changes.
            </p>
            <div className="font-bold">Contact Us</div>
            <p>
              If you have any queries relating to the processing/ usage of
              information provided by you or Rasodaa's Privacy Policy, you may
              email us on{" "}
              <a href="mailto:info@cosmostaker.com" className="text-blue-700">
                info@cosmostaker.com
              </a>
            </p>
            <p className="mt-5">
              For Indian users
              <br />
              326 Milinda Manor, RNT Marg,
              <br />
              Opp Central Mall, Chhoti Gwaltoli, Indore,
              <br />
              Madhya Pradesh 452001
              <br />
            </p>
          </div>
        </section>
        <Footer />
      </section>
      <PageTop />
    </>
  );
};

export default PrivacyPolicy;
