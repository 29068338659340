import React from "react";
import "../../App.css";

const Popup = ({ message, success, show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div className="text-xl mb-4">
          <div className="flex items-center text-green-600">
            {success ? (
              <div class="notificationCard">
                <p class="notificationHeading">Success</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                >
                  <circle cx="50" cy="50" r="40" fill="green" />
                  <path
                    d="M20 50 L40 70 L80 30"
                    stroke="white"
                    stroke-width="10"
                    fill="transparent"
                  />
                </svg>

                <p class="notificationPara text-lg">{message}</p>
                <div class="buttonContainer">
                  <button class="AllowBtn" onClick={()=>onClose()} >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              message
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
