import Navbar from "../../Navbar/secondNav";
import PageTop from "../../Ui-Elements/pageTopArrow";
import Footer from "../footer";

const ShippingPolicy = () => {
  return (
    <>
      <Navbar />
      <section className="shadow-3xl p-[20px] mt-10 rounded-[40px]  mb-10 custom-container">
        <section className="m-4">
          <h1 className="text-center text-5xl m-6">
            Shipping and Delivery Policy
          </h1>
          {/* <p className="text-center">Last updated on August 23, 2020.</p> */}
          <br />
          <ul className="text-black ml-10" style={{ listStyleType: "disc" }}>
            <li className="text-black">
              Rasodaa provides online ordering services by entering into
              contractual arrangements with chef partners("Chefs").
            </li>
            <li className="text-black">
              Customers can access the Rasodaa Platform's menu items or Products
              and place online orders through Rasodaa.
            </li>
            <li className="text-black">
              Your request to order food and beverages or Products from a Chef
              on the Rasodaa Platform constitutes an unconditional and
              irrevocable authorization granted to Rasodaa to place online
              orders for food and beverages or Products on your behalf against
              the Chef(s).
            </li>
            <li className="text-black">
              The Chef may deliver an order placed by you through the Rasodaa
              Platform directly, or Rasodaa may facilitate delivery through a
              third-party who may be available to provide delivery services to
              you ("Delivery Partners"). Rasodaa is simply acting as an
              intermediary between you and the Delivery Partners, or between you
              and the Chef, in both of these cases.
            </li>
            <li className="text-black">
              The acceptance by a Delivery Partner of undertaking delivery of
              your order shall constitute a contract of service between you and
              the Delivery Partner under the Consumer Protection Act, 2019 or
              any successor legislations, to which Rasodaa is not a party under
              any applicable law. It is clarified that Rasodaa does not provide
              delivery or logistics services and only facilitates the delivery
              of food and beverages or Products ordered by customers through the
              Rasodaa Platform by connecting them with delivery partners or the
              Chef, as the case may be.
            </li>
            <li className="text-black">
              Rasodaa shall not be liable for any acts or omissions on the part
              of the Delivery Partner or Chef, including deficiency in service,
              wrong delivery of order, time taken to deliver the order, order
              package tampering, and so on, where Rasodaa facilitates delivery
              of an order placed by you.
            </li>
            <li className="text-black">
              Rasodaa, the Delivery Partner, or the Chef may charge you a
              delivery fee for delivering your order. You agree that Rasodaa is
              authorized to collect Delivery Charges on behalf of the Chef or
              the Delivery Partner for the delivery service provided by the Chef
              or the Delivery Partner, as applicable. The Delivery Charges may
              vary from order to order and are based on a number of factors,
              including but not limited to order value, distance, and time of
              day. Rasodaa will notify you of any applicable Delivery Charges,
              but you will be responsible for any Delivery Charges incurred for
              your order regardless of your awareness of such Delivery Charges.
            </li>
            <li className="text-black">
              In addition to the Delivery Charges, you may be charged an amount
              towards delivery surge for delivery of your order facilitated by
              the Delivery Partner or the chef, which is determined based on
              various factors such as but not limited to distance covered, time
              taken, demand for delivery, real-time analysis of traffic and
              weather conditions, seasonal peaks, or such other parameters as
              may be determined from time to time ("Delivery Surge"). You agree
              that Rasodaa is authorized to collect the Delivery Surge on behalf
              of the Chef or the Delivery Partner for the delivery service
              provided by the Chef or the Delivery Partner, as applicable. The
              Delivery Surge may vary from order to order, which may be
              determined on multiple factors which shall include but not be
              limited to Chef, order value, distance, demand during peak hours.
              Rasodaa will use reasonable efforts to inform you of the Delivery
              Surge that may apply to you, provided you will be responsible for
              the Delivery Surge incurred for your order regardless of your
              awareness of such Delivery Surge.
            </li>
            <li className="text-black">
              In respect of the order placed by You, Rasodaa shall issue
              documents like order summary, tax invoices, etc. as per the
              applicable legal regulations and common business practices.
            </li>
            <br />
            <p className="text-black">
              All prices listed on the Rasodaa Platform are provided by Chefs at
              the time of publication and have been placed as received from the
              Chef. While we make every effort to keep them up to date, the
              final price charged to you by the Chef at the time of delivery may
              change. In the event of a price conflict between the Rasodaa
              Platform and the price charged by the Chef, the price charged by
              the Chef shall be deemed to be the correct price, except for the
              Rasodaa Delivery Charge.
            </p>
          </ul>
          <br />
          <p className="mt-5">
            For Indian users
            <br />
            326 Milinda Manor, RNT Marg,
            <br />
            Opp Central Mall, Chhoti Gwaltoli, Indore,
            <br />
            Madhya Pradesh 452001
            <br />
          </p>
        </section>
        <Footer />
      </section>
      <PageTop />
    </>
  );
};

export default ShippingPolicy;
