import React, { useState, useEffect } from "react";

const PageTop = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <button
        className={`fixed bottom-4 right-2 bg-red-500 text-white px-4 py-3 rounded-lg shadow-md ${
          showScrollButton ? "block" : "hidden"
        }`}
        onClick={scrollToTop}
      >
        <i class="fa fa-arrow-up"></i>
      </button>
    </>
  );
};

export default PageTop;
