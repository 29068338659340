import "./App.css";
import ContactUs from "./components/Rasoda/contactus";
import Footer from "./components/Rasoda/footer";
import SellOnRasoda from "./components/Rasoda/sellOnRasoda";
import HowItWorks from "./components/Rasoda/howItWorks";
import Home from "./components/Rasoda/home";
import Navbar from "./components/Navbar/navbar";
import PageTop from "./components/Ui-Elements/pageTopArrow";

const Main = () => {
  return (
    <>
      <Navbar />
      <section className="shadow-3xl p-[20px] mt-10 rounded-[40px]  mb-10 custom-container">
        {/* ==================================================Home====================================================== */}

        <section className="banner" id="home" path="/home">
          <Home />
        </section>
        {/* ==================================================How It Works====================================================== */}

        <section className="secondBg mt-12 sm:mt-24" id="HowItWorks">
          <HowItWorks />
        </section>

        {/* ====================================================Sell On Rasodaa===================================================== */}

        <section className="thirdBg mt-12 sm:mt-24" id="sellOnRasoda">
          <SellOnRasoda />
        </section>

        {/* ==================================================Contact-US====================================================== */}

        <section id="contactUs">
          <ContactUs />
        </section>

        {/* ==================================================Footer====================================================== */}

        <section>
          <Footer home={"home"} />
        </section>
      </section>
      <PageTop />
    </>
  );
};

export default Main;
