import Navbar from "../../Navbar/secondNav";
import Footer from "../footer";

const AboutUs = () => {
  return (
    <>
      <div>
        <Navbar />
        <section className="shadow-3xl p-[20px] mt-10 rounded-[40px]  mb-10 custom-container">
          <section id="aboutUs">
            <div className="container">
              <h1 className="text-center text-5xl font-bold">About Us</h1>
              <p className="text-start text-gray-900 mt-20">
                Well, Guys! Rasodaa's journey started a year ago when I began
                living in a hostel. It was my first experience of hostel life. I
                miss my mom's handmade cooked food very much.
                <br />
                That's where the idea for Rasodaa began. I thought, why can't we
                create a platform where anyone living away from home due to
                their responsibilities can satisfy their cravings for
                home-cooked food?
              </p>
              <p className="text-start text-gray-900">
                So, we started taking steps towards developing Rasodaa, where
                our mission is to help anybody, anywhere stop their home food
                cravings with Rasodaa. At Rasodaa, we offer opportunities for
                home chefs to showcase their talent.
                <br />
                <br />
                So, what are you waiting for? Start selling or buying delicious
                dishes on Rasodaa.
                <br />
                <br />
                Best,
                <br />
                Rasodaa Team
              </p>
            </div>
          </section>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default AboutUs;
// &emsp;
