import React, { useState } from "react";
import logo from "../../assets/images/rasodaa-logo-removebg-preview.png";
import investorIcon from "../../assets/images/investor.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-black border-gray-200 dark:bg-gray-900 w-full">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="relative text-white text-md font-14 group">
          <img src={logo} alt="logo" />
        </Link>
        <button
          onClick={toggleMenu}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`w-full md:block md:w-auto ${
            isMenuOpen ? "block" : "hidden"
          }`}
          id="navbar-default"
        >
          <ul className="font-medium flex  flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-black md:flex-row gap-3 md:space-x-8 md:mt-0 md:border-0 md:bg-black dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 ">
            <li>
              <Link
                to="/news"
                className="relative text-white text-md top-2 font-14 group sm:gap-4"
              >
                NEWS
                <span className="absolute top-5 inset-x-0 bottom-0 h-1 bg-white rounded-lg transform scale-x-0 transition duration-300 group-hover:scale-x-100"></span>
              </Link>
            </li>
            <li>
              <Link
                to="/ourculture"
                className="relative text-white text-md top-2 font-14 group sm:gap-4"
              >
                OUR CULTURE
                <span className="absolute top-5 inset-x-0 bottom-0 h-1 bg-white rounded-lg transform scale-x-0 transition duration-300 group-hover:scale-x-100"></span>
              </Link>
            </li>
            <li>
              <a
                href="#HowItWorks"
                className="relative text-white top-2 text-md font-14 group"
              >
                HOW IT WORKS
                <span className="absolute top-5 inset-x-0 bottom-0 h-1 bg-white rounded-lg transform scale-x-0 transition duration-300 group-hover:scale-x-100"></span>
              </a>
            </li>
            <li>
              <a
                href="#contactUs"
                className="relative text-white top-2 text-md font-14 group"
              >
                CONTACT US
                <span className="absolute top-5 inset-x-0 bottom-0 h-1 bg-white rounded-lg transform scale-x-0 transition duration-300 group-hover:scale-x-100"></span>
              </a>
            </li>
            <li className="bg-red-600 p-2 px-6 top-1 text-15 font-semibold rounded-lg justify-center">
              <a
                href="#sellOnRasoda"
                className="relative text-white text-md font-14 group"
              >
                SELL ON RASODAA
              </a>
            </li>
            <li>
              <Link class="button" to="/investor">
                <svg class="bell" viewBox="0 0 448 512">
                  <path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"></path>
                </svg>
                Become an Investor
                <div class="arrow">›</div>
              </Link>
              {/* <a href="#" class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Read more
            <svg class="w-3.5 h-3.5 ml-2 arrow" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </a> */}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
