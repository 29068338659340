import appVideo from "../../components/images/app.mp4";
import adddishes from "../../components/images/adddishes.mp4";
import selectdeliverytype from "../../components/images/selectdeliverytype.mp4";
import startgettingorders from "../../components/images/startgettingorders.mp4";

const SellOnRasoda = () => {
    return (
        <>
                  <div className="container mx-auto px-4">
            <div className="flex justify-center ">
              <h1 className="mt-8 sm:mt-20 font-bold text-2xl sm:text-4xl">
                Sell On Rasodaa
              </h1>
            </div>
            <div className="flex flex-wrap justify-around p-10 mb-10 gap-10 -mx-4 ">
              <div className="w-48 sm:w-52 h-52 sm:h-60 mb-10">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-3xl shadow-seddow-video"
                >
                  <source src={appVideo} type="video/mp4" />
                </video>
                <h2 className="text-edit sell-btn-2 text-center">Open App</h2>
              </div>
              <div className="w-48 sm:w-52 h-52 sm:h-60 mb-10">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-3xl shadow-seddow-video"
                >
                  <source src={adddishes} type="video/mp4" />
                </video>
                <h2 className="text-edit sell-btn-2 text-center">Add Dishes</h2>
              </div>
              <div className="w-48 sm:w-52 h-52 sm:h-60 mb-10">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-3xl shadow-seddow-video"
                >
                  <source src={selectdeliverytype} type="video/mp4" />
                </video>
                <h2 className="text-edit sell-btn-2 text-center">
                  Add Delivery Options
                </h2>
              </div>
              <div className="w-48 sm:w-52 h-52 sm:h-60  mb-10">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-3xl shadow-seddow-video"
                >
                  <source src={startgettingorders} type="video/mp4" />
                </video>
                <h2 className="text-edit sell-btn-2 text-center">
                  Start Getting Orders
                </h2>
              </div>
            </div>
          </div>
        </>
    )
}

export default SellOnRasoda;