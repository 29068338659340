import Navbar from "../../Navbar/secondNav";
import PageTop from "../../Ui-Elements/pageTopArrow";
import Footer from "../footer";

const RefundPolicy = () => {
  return (
    <>
      <Navbar />
      <section className="shadow-3xl p-[20px] mt-10 rounded-[40px]  mb-10 custom-container">
        <section>
          <h1 className="text-center text-5xl font-bold mb-4">
            Cancellation and refund policy
          </h1>
          {/* Uncommented the line below as it's not needed */}
          {/* <p className="text-center">Last updated on August 23, 2020.</p> */}
          <br />
          <ul className="text-black ml-10" style={{ listStyleType: "disc" }}>
            <li>
              You acknowledge that (1) your cancellation, attempted or purported
              cancellation of an order, or (2) cancellation for reasons not
              attributable to Rasodaa, such as providing incorrect particulars,
              contact number, delivery address, etc., or being unresponsive, not
              reachable, or unavailable for fulfillment of the services offered
              to you, shall constitute a breach of your unconditional and
              irrevocable authorization in favor of Rasodaa to place that order.
              In the event you commit an Authorization Breach, you shall be
              liable to pay the liquidated damages of an amount equivalent to
              the order value. You hereby authorize Rasodaa to deduct or collect
              the amount payable as liquidated damages through such means as
              Rasodaa may determine in its discretion, including without
              limitation, by deducting such amount from any payment made towards
              your next Order.
            </li>
            <li>
              Rasodaa may be unable to accept or cancel your order for a variety
              of reasons, including, but not limited to, technical errors,
              unavailability of the ordered item(s), or any other reason
              attributable to Rasodaa, Chef, or Delivery Partner. Rasodaa will
              not charge you a cancellation fee in such cases. If you cancel
              your order after payment has been charged and you are eligible for
              a refund of the order value or a portion of it, the amount will be
              refunded to you.
            </li>
            <li>
              No replacement / refund / or any other resolution will be provided
              without Chef(s)’ permission.
            </li>
            <li>
              If you have a complaint about an order, including but not limited
              to food spillage, foreign objects in food, delivery of the wrong
              order or food and beverages or Products, or poor quality, you must
              provide proof of the same before any resolution can be provided.
            </li>
            <li>
              All refunds shall be processed in the same manner as they are
              received, unless refunds have been provided to you in the form of
              credits, refund amount will reflect in your account based on
              respective banks' policies.
            </li>
          </ul>
          <br />
          <p className="mt-5">
            For Indian users
            <br />
            326 Milinda Manor, RNT Marg,
            <br />
            Opp Central Mall, Chhoti Gwaltoli, Indore,
            <br />
            Madhya Pradesh 452001
            <br />
          </p>
        </section>
        <Footer />
      </section>
      <PageTop />
    </>
  );
};

export default RefundPolicy;
