import React, { useState } from "react";
import logo from "../.././assets/images/rasodaa-logo-removebg-preview.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-black border-gray-200 dark:bg-gray-900 w-full">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="relative text-white text-md font-14 group">
          <img src={logo} alt="logo" />
        </Link>
        <button
          onClick={toggleMenu}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`w-full md:block md:w-auto ${
            isMenuOpen ? "block" : "hidden"
          }`}
          id="navbar-default"
        >
          <ul className="font-medium flex  flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-black md:flex-row gap-3 md:space-x-8 md:mt-0 md:border-0 md:bg-black dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 ">
            <li>
              <Link
                to="/home"
                className="relative text-white text-md top-2 font-14 group sm:gap-4"
              >
                TOP CHEFS
                <span className="absolute top-5 inset-x-0 bottom-0 h-1 bg-white rounded-lg transform scale-x-0 transition duration-300 group-hover:scale-x-100"></span>
              </Link>
            </li>
            <li>
              <Link
                to="/howItWork"
                href="#HowItWorks"
                className="relative text-white top-2 text-md font-14 group"
              >
                HOW IT WORKS
                <span className="absolute top-5 inset-x-0 bottom-0 h-1 bg-white rounded-lg transform scale-x-0 transition duration-300 group-hover:scale-x-100"></span>
              </Link>
            </li>
            <li>
              <Link
                to="/contactUs"
                className="relative text-white top-2 text-md font-14 group"
              >
                CONTACT US
                <span className="absolute top-5 inset-x-0 bottom-0 h-1 bg-white rounded-lg transform scale-x-0 transition duration-300 group-hover:scale-x-100"></span>
              </Link>
            </li>
            <li className="bg-red-600 p-2 px-6 top-1 text-15 font-semibold rounded-lg justify-center">
              <Link
                to="/sellOnRasoda"
                className="relative text-white text-md font-14 group"
              >
                SELL ON RASODAA
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
