import AppStore from "../../components/images/AppStore.3a5b8df3.svg";
import GooglePlay from "../../components/images/GooglePlay.32d7baa8.svg";
import seal from "../../assets/images/1.png";
import appImg from "../../components/images/app-img.png";

const Home = () => {
    return (
        <>
          <div class="flex flex-wrap ralative lg:ml-12">
            <div class="lg:ml-[400px] cont sm:mt-4 md:mt-10 lg:mt-20">
              <div className="relative font-sans">
                <h1 className="absolute top-3 text-center font-sans lg-3 font-bold text-2xl sm:text-xl md:text-2xl lg:text-3xl">
                  Get home-cooked delicious food, right at your door
                </h1>
                <h1 className="absolute h1 top-24 font-sans font-serif text-center w-full font-semibold text-xl sm:text-xl md:text-2xl lg:text-2xl">
                  App is Available On
                </h1>
              </div>

              <div class="flex flex-wrap gap-4 sm:gap-6 justify-center mt-[160px] sm:mt-[200px] md:mt-[160px] md:ml-0 ">
                <div className="text-center">
                  <button data-js="open">
                    <a
                      href="https://apps.apple.com/in/app/rasoda/id1607453111"
                      class="apple-store"
                    >
                      <img
                        src={AppStore}
                        alt="image not found"
                        height={46}
                        width={155}
                      />
                    </a>
                  </button>
                </div>
                <div>
                  <button data-js="open">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.rasoda"
                      class="google-pay"
                    >
                      <img
                        src={GooglePlay}
                        alt="image not found"
                        height={46}
                        width={155}
                      />
                    </a>
                  </button>
                </div>
              </div>
              <div class="flex justify-center mt-4 md:mt-8 sm:ml-0">
                <button class="w-28 sm:w-40 h-10 sm:h-12 rounded-tl-lg rounded-bl-lg rounded-tr-lg text-white font-semibold bg-gray-900 text-base sm:text-lg cursor-pointer">
                  Order Now →
                </button>
              </div>

              <div class="flex justify-center md:mt-4 lg:mt-4 sm:mt-6 sm:ml-0 ">
                <img
                  src={seal}
                  alt="image not found"
                  height={100}
                  width={100}
                  className="seal"
                />
              </div>
            </div>

            <div class="flex-3 ml-auto mr-10 mt-1">
              <img
                src={appImg}
                alt="image not found"
                className="sm:mr-10 md:mr-0 imgCls lg:mr-0"
              />
            </div>
          </div>
        </>
    )
}

export default Home;