// import appVideo from "./components/images/app.mp4";
import appVideo from "../../components/images/app.mp4";
import search from "../../components/images/search-food.mp4";
import delivery from "../../components/images/delivery-guy.mp4";
import cash from "../../components/images/cash-on-delivery.mp4";
import "../../App.css";

const HowItWorks = () => {

    return (
        <>
         {/* ==================================================How It Works====================================================== */}

         <div className="container mx-auto px-4">
            <div className="flex justify-center">
              <h1 className="mt-8 sm:mt-20 font-bold text-2xl sm:text-4xl">
                How It Works
              </h1>
            </div>
            <div className="flex flex-wrap justify-around p-10 mb-10 gap-10 -mx-4">
              <div className="w-48 sm:w-52 h-52 sm:h-60 mb-10">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-3xl shadow-seddow-video"
                >
                  <source src={appVideo} type="video/mp4" />
                </video>
                <h2 className="text-edit sell-btn-2 text-center">Open App</h2>
              </div>
              <div className="w-48 sm:w-52 h-52 sm:h-60 mb-10">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-3xl shadow-seddow-video"
                >
                  <source src={search} type="video/mp4" />
                </video>
                <h2 className="text-edit sell-btn-2 text-center">
                  Search Dish
                </h2>
              </div>
              <div className="w-48 sm:w-52 h-52 sm:h-60 mb-10">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-3xl shadow-seddow-video"
                >
                  <source src={delivery} type="video/mp4" />
                </video>
                <h2 className="text-edit sell-btn-2 text-center">
                  Add Delivery Details
                </h2>
              </div>
              <div className="w-48 sm:w-52 h-52 sm:h-60  mb-10">
                <video
                  autoPlay
                  muted
                  loop
                  className="rounded-3xl shadow-seddow-video"
                >
                  <source src={cash} type="video/mp4" />
                </video>
                <h2 className="text-edit sell-btn-2 text-center">
                  Place Order
                </h2>
              </div>
            </div>
          </div>
        </>
    )
}

export default HowItWorks;