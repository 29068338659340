import logo from "../../assets/images/rasodaa-logo-removebg-preview.png";
import "../../App.css";
import { Link } from "react-router-dom";

const Footer = ({ home, HowItWorks, sellOnRasoda, contactUs }) => {
  return (
    <>
      <div class="" id="footer">
        <div class="container mx-auto flex flex-wrap">
          <div class="w-full sm:w-1/2 md:w-1/4 p-4 text-center">
            <div
              class="mb-4 mt-[-15px] lg:mr-14"
              style={{ "text-align": "-webkit-center" }}
            >
              <img src={logo} alt="image not found" class="" />
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/4 p-4 text-center">
            <div>
              <h2 class="text-lg font-semibold mb-2">Our Services</h2>
              <ul className="text-gray-900 font-semibold">
                <li class="mb-2 hover:text-white">
                  <Link to="/Aboutus">About Us</Link>
                  {/* <Link to={`/Aboutus/${home}`}>home</Link> */}
                </li>
                <li class="mb-2 hover:text-white">
                  <Link to="/privacyPolicy">Privacy Policy</Link>
                </li>
                <li class="mb-2 hover:text-white">
                  <Link to="/refundPolicy">Cancellation-Refund Policy</Link>
                </li>
                <li class="mb-2 hover:text-white">
                  <Link to="/contentPolicy">Guideline & Policies</Link>
                </li>
                <li class="mb-2 hover:text-white">
                  <Link to="/shippingPolicy">Shipping Policies</Link>
                </li>
                <li class="mb-2 hover:text-white">
                  <Link to="/terms&conditions">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/4 p-4 text-center ">
            <div>
              <h2 class="text-lg font-semibold mb-2">Follow Us On</h2>
              <ul id="socials">
                <li class="mb-2">
                  <a href="https://www.facebook.com/Rasodaa.org">
                    <i class="fab fa-facebook-f icon"></i>
                  </a>
                </li>
                <li class="mb-2">
                  <a href="javascript:void(0)">
                    <i class="fab fa-twitter icon"></i>
                  </a>
                </li>
                <li class="mb-2">
                  <a href="https://www.instagram.com/rasoda22">
                    <i class="fab fa-instagram icon"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/4 p-4 text-center">
            <div>
              <h2 class="text-lg font-semibold mb-2">Address</h2>
              <ul className="text-gray-700">
                <li class="mb-2">326 Milinda Manor, RNT Marg,</li>
                <li class="mb-2">Opp Central Mall, Chhoti Gwaltoli, Indore,</li>
                <li class="mb-2">Madhya Pradesh 452001</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
