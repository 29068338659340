import Navbar from "../../Navbar/secondNav";
import PageTop from "../../Ui-Elements/pageTopArrow";
import Footer from "../footer";

const ContentPolicy = () => {
  return (
    <>
      <Navbar />
      <section className="shadow-3xl p-[20px] mt-10 rounded-[40px]  mb-10 custom-container">
        <section className="m-4">
          <h1 className="text-center text-5xl m-6">Guidelines and Policies</h1>

          <p className="text-black">
            PS: Rasodaa is a product of CosmostakeR (
            <a href="http://cosmostaker.com/">cosmostaker.com</a>), having its
            principal office in Indore, all the references made to Rasodaa in
            this document can be applied to CosmostakeR.
          </p>

          <p className="text-black">
            We welcome your feedback and descriptions of your fresh meal
            experiences. However, there are a few things we expect from all
            Rasodaa applications or software users. Your reviews and photos, as
            well as your profile and the comments you share, should always
            correspond with our policies and terms; if your activity on Rasodaa
            does not correspond with these terms, we reserve the right to take
            appropriate action. This could include, with or without notice,
            changing or deleting your reviews or comments, restricting your
            review activity, or deleting your account entirely. If you believe
            your review was removed in error, please contact us at{" "}
            <a href="mailto:info@cosmostaker.com">info@cosmostaker.com</a>.
          </p>

          <p className="text-black">
            If you see content that does not align with these guidelines or our
            Terms of Service, please let us know. We will consider all reports.
            However, due to the diversity of our community, it is possible that
            content disagreeable to you might not meet the criteria to be
            removed.
          </p>

          <br />

          <strong className="text-black">Keep it relevant</strong>
          <p className="text-black">
            Please keep your contributions Rasodaa-related. Accounts that post
            irrelevant, inappropriate, or promotional content, reviews with
            server names, reviews with similar digital signatures that spam
            chefs, or are based on a chef's previously disclaimed or informed
            policies and practices, may be deleted without notice. If we remove
            or moderate your review and you repost another experience for the
            same chef, we reserve the right to remove that review as well, even
            if it is in line with our content guidelines. In the event of spam,
            we may limit your Rasodaa review activity.
          </p>

          <br />

          <strong className="text-black">Keep it clean</strong>
          <p className="text-black">
            Keep foul/abusive/hateful language, threats, and lewdness out of
            your writing, whether it's a small snippet or a delightfully
            detailed account of your meal. We, like you, despise junk and will
            delete it whenever we come across it. This includes (but is not
            limited to) derogatory remarks about someone's protected
            characteristics (e.g., race, gender, religion) or indications of a
            personal vendetta against a company and its employees.
          </p>

          <br />
          <strong className="text-black">Keep it real</strong>
          <p className="text-black !important">
            Write your review based on facts and your own experiences (not those
            of friends, hearsay, or media reports). Please don't exaggerate or
            fabricate your story. We do not take sides in disputes, so make sure
            you can keep your word. Content indicating that the reviewer has not
            even visited or used the chef's services will be removed. As an
            example, "I've never been here and don't intend to. The management
            is terrible, and the food is even worse!" Reviews that are
            deceptive, false, or misleading will be removed. Reviewing a slew of
            fast-food restaurants in quick succession (even if you eat at them
            more frequently than you should) is considered suspicious activity,
            and these reviews are likely to be moderated. Accepting or
            soliciting a monetary or non-monetary kickback in exchange for
            reviews or photographs is also not acceptable and may result in the
            removal of your profile.
          </p>

          <strong className="text-black">Don't solicit</strong>
          <p className="text-black !important">
            Identifying or promoting yourself as an official Rasodaa blogger, or
            using your status to solicit any kind of benefit (including but not
            limited to accepting money, free meals, or drinks) in exchange for
            reviews, or under the threat of negative Rasodaa reviews, is not
            permitted. If we receive reports or evidence of such incidents, we
            reserve the right to delete your Rasodaa profile or take any other
            action we deem appropriate, with no questions asked. 'Solicitation'
            acts include the following:
          </p>
          <p className="text-black !important">
            Explicit/implied agreement (written or verbal) to write reviews in
            exchange for money, a free meal(s), drinks, or other consideration.
            Participating in social media groups (for example, WhatsApp/Facebook
            or any other social media platform) that offer incentives in
            exchange for reviews.
          </p>

          <strong className="text-black">Don't steal</strong>
          <p className="text-black !important">
            We take plagiarism very seriously. Copying other people's reviews or
            photos from Rasodaa or other platforms, or even reposting your own
            in multiple places on Rasodaa (in full or in part), is not tolerated
            and may result in removal/moderation. We believe you will play
            fairly and create your own content.
          </p>

          <strong className="text-black">Be yourself</strong>
          <p className="text-black !important">
            Your profile is your identity on Rasodaa, so keep it real. We want
            to stay clean, and we might end up removing your account depending
            on how inappropriate your profile is.
          </p>

          <strong className="text-black">Don't be a bully</strong>
          <p className="text-black !important">
            We take blackmail or threatening behaviour allegations against Chef
            and its associates very seriously. This activity is strictly
            prohibited by our policies and may also be illegal. Reviews
            submitted to blackmail a chef or his associates will be removed.
          </p>

          <strong className="text-black">Play by the rules</strong>
          <p className="text-black !important">
            Your activity and the content you add to the Rasodaa platform should
            be in accordance with Rasodaa's Terms and Conditions as well as
            local laws and regulations. Local laws take precedence, and content
            may be moderated or removed to comply.
          </p>

          <strong className="text-black">
            Seek help from the right people
          </strong>
          <p className="text-black !important">
            Rasodaa is a website and mobile application that makes no claims to
            be a court of law or a health ministry. If you have a disagreement
            with a chef or believe he or she is not following health codes, we
            encourage you to contact the appropriate authorities directly.
            Rasodaa is not the place to report illegal activities, physical
            confrontations, health code violations, or anything else that is
            being investigated by local governing bodies or law enforcement
            personnel. Rather than being mentioned in reviews, we recommend that
            such accusations be reported to the appropriate authorities.
          </p>
          <div>
            <strong className="text-black">Photo guidelines</strong>
            <p className="text-black">
              Violation of the below mentioned pointers may lead to appropriate
              legal action, reduced visibility, or deletion of your pictures.
            </p>
            <ul className="text-black ml-10" style={{ listStyleType: "disc" }}>
              <li>
                Photos that are exact duplicates of other photos, multiple
                clicks of the same dish or ambiance or taken from different
                angles
              </li>
              <li>Plagiarized photos</li>
              <li>Copyright photos</li>
              <li>Photos of others are posted without their permission.</li>
              <li>Photos that contain promotional content</li>
              <li>Photos that contain subjects such as people in them</li>
              <li>Photos that are blurry or unclear</li>
              <li>
                Photos that contain text or are not related to the dine-in
                experience
              </li>
              <li>Photos of half-eaten food items</li>
              <li>Photos of packed food items/packaging</li>
              <li>Photos of food under preparation</li>
              <li>Photos containing watermarks or write-ups of any kind</li>
              <li>Unpleasant photos that ruin people's appetites</li>
              <li>Obscene photos</li>
            </ul>
          </div>

          <div>
            <strong className="text-black">Chef guidelines</strong>
            <p className="text-black">
              Rasodaa is the most popular way for chefs to reach out to a vast
              food community. A few things you should keep in mind:
            </p>
            <ul className="text-black ml-10" style={{ listStyleType: "disc" }}>
              <li>
                Keep your listing updated: While our team makes every effort to
                keep Rasodaa information up to date, we appreciate it when you
                let us know when an update is needed. Customers who arrive late
                will be unhappy if your timings change and your listing is not
                updated.
              </li>
              <li>
                Don't ask for reviews: The best way to get reviews is to please
                your customers with your food and service. Selective
                solicitation is a no-no, and offering any type of compensation
                or kickback for reviews is also unethical, so avoid it at all
                costs.
              </li>
              <li>
                Don’t ever offer freebies, discounts, or payment in exchange for
                reviews.
              </li>
              <li>Don't offer incentives for users to remove reviews.</li>
              <li>
                Don’t ask your staff to compete with each other to collect
                reviews.
              </li>
              <li>
                Don’t work with companies or third-party vendors/PR agencies
                offering to 'fix your reviews/ratings'
              </li>
              <li>
                While foodie meetups are permitted, reviews written in lieu of
                these official gatherings must include a clear disclaimer to
                help others identify that the review is based on an invitation.
                However, an invitation does not imply that the user must leave a
                positive review.
              </li>
              <li>
                The businesses that do best on Rasodaa are the ones that provide
                a great customer experience to everyone who walks in the door
                without any expectation or encouragement that they write a
                positive review.
              </li>
              <li>
                When you receive a negative review, take two deep breaths and
                respond positively. If necessary, three. This is something that
                all chefs who have customers experience. Even when they are
                incorrect, these customers are always correct. Take the negative
                feedback as constructive feedback and use it to improve things.
                Take the criticism in stride, whether you agree with it or not.
                You may respond with a management response to demonstrate your
                concern, but please do not use this as a platform to retaliate
                or offer the customer an incentive to edit their review. Your
                response cannot be edited after it has been posted, so choose
                your words carefully.
              </li>
              <li>
                Don't entertain: Meeting the demands of customers who request
                benefits under the threat of negative reviews or ratings sends
                the message that such behaviour is tolerated. Inviting users who
                have reviewed and given a low rating back to the chef for a
                complimentary meal, on the other hand, invites false negative
                reviews. These issues affect the entire community and require
                everyone's involvement to be addressed. Users who engage in such
                behaviour should be reported to info@cosmostaker.com.
              </li>
              <li>
                Chefs, employees, and any affiliates with business interests are
                not permitted to write reviews on Rasodaa as part of a concerted
                effort to maintain the neutrality of content on Rasodaa. We
                understand that you are passionate about food and would like to
                share your experiences as a member of the food and beverage
                industry. But when you're emotionally invested in your company,
                it's difficult to leave emotions (and bias) at the door. This is
                a step toward preserving the neutrality - and quality - of
                Rasodaa's content.
              </li>
              <li>
                It's also worth noting that we (Rasodaa) do not have any
                employees or affiliates who are paid to review. It is not true
                if any guest identifies themselves as an official blogger,
                review employee, or associate of Rasodaa. Rasodaa employees are
                contractually and ethically prohibited from using their position
                to obtain free meals or preferential treatment. If you come
                across such a practise, please report it to
                info@cosmostaker.com. If evidence is provided, appropriate
                action will be taken against the user or employee.
              </li>
            </ul>
          </div>

          <div>
            <strong className="text-black">
              Rasodaa Employee Code of Ethics
            </strong>
            <p className="text-black">
              Rasodaa employees are expected to adhere to and uphold the highest
              ethical and integrity standards. This includes always acting in
              accordance with our core values and the policies listed below.
            </p>
            <p className="text-black">
              Rasodaa employees are not permitted to use their employee status
              to obtain discounts or freebies. Rasodaa employees are not to
              identify themselves as Rasodaa employees unless they are visiting
              a chef's location on official business.
            </p>
            <p>
              All Rasodaa employees in client-facing roles, including but not
              limited to Sales, Sales Support, Neutrality, Media Content, and
              Client Servicing across all transaction/business/function at
              Rasodaa, are prohibited from writing reviews and/or rating dine
              out experiences on Rasodaa from their personal or Rasodaa
              accounts. They are also prohibited from influencing others to
              write biassed reviews or assign ratings.
            </p>
            <p>
              Rasodaa's review and photo moderators must always act in favour of
              maintaining Rasodaa as a neutral platform. They must use their
              best judgement when implementing moderation guidelines and are not
              permitted to give chefs preferential treatment. Delete any genuine
              review from a chef page is against our policies. Similarly,
              keeping reviews that have been proven to be false is against our
              policies.
            </p>
            <p>
              Please email{" "}
              <a href="mailto:info@cosmostaker.com">info@cosmostaker.com</a> if
              you witness any behaviour not in compliance with this code, as it
              is in direct violation of employee contracts.
            </p>
          </div>

          <p className="mt-5">
            For Indian users
            <br />
            326 Milinda Manor, RNT Marg,
            <br />
            Opp Central Mall, Chhoti Gwaltoli, Indore,
            <br />
            Madhya Pradesh 452001
            <br />
          </p>
        </section>
        <Footer />
      </section>
      <PageTop />
    </>
  );
};

export default ContentPolicy;
