import { Link } from "react-router-dom";

const MobileTermsConditions = () => {
  return (
    <>
      <section className="shadow-3xl p-[20px] mt-10 rounded-[40px]  mb-10 custom-container">
        <section className="m-4">
          <h1 className="text-center text-5xl font-bold m-6 ">Terms Of Use</h1>
          <p>
            PS: Rasodaa is a product of CosmostakeR (
            <a
              href="http://www.cosmostaker.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 underline"
            >
              cosmostaker.com
            </a>
            ), having its principal office in Indore, all the references made to
            Rasodaa in this document can be applied to CosmostakeR.
          </p>
          <p>
            CosmostakeR and/or its affiliates (referred to as "Rasodaa," the
            "company," "we," "us," and "our") are intended to make you aware of
            your legal rights and responsibilities with respect to your access
            to and use of the Rasodaa website at{" "}
            <Link to="/" className="text-blue-500">
              https://Rasodaa.in/
            </Link>{" "}
            (the "Site") and any related mobile or software applications
            ("Rasodaa Platform"), including but not limited to delivery of
            information via the website, whether existing now or in the future,
            that links to the Terms (collectively, the "Services"). This term
            describes:
          </p>
          <div className="font-bold">I. Acceptance of terms</div>
          <p>
            These terms are effective for all existing and future Rasodaa
            customers and users.
          </p>
          <p>
            Please read these terms carefully. By accessing or using the Rasodaa
            Platform, you are agreeing to these terms and concluding a legally
            binding contract with CosmostakeR and/or its affiliates. You may not
            use the services if you do not accept the terms or are unable to be
            bound by them. Your use of the Rasodaa Platform is at your own risk,
            including the risk that you might be exposed to content that is
            objectionable or otherwise inappropriate.
          </p>
          <p>
            In order to use the services, you must first agree to the terms. You
            can accept the terms by:
          </p>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              Clicking to accept or agree to the terms where it is made
              available to you by Rasodaa in the user interface for any
              particular service; or
            </li>
            <li>
              Actually using the services. In this case, you understand and
              agree that Rasodaa will treat your use of the services as
              acceptance of the terms from that point onwards.
            </li>
          </ul>
          <div className="font-bold">II. Definitions</div>
          <p className="font-bold">Customer</p>
          <p>
            "Customer" or "You" or "Your" refers to you, as a customer of the
            services. A customer is someone who accesses or uses the services
            for the purpose of sharing, displaying, hosting, publishing,
            transacting, or uploading information or views or pictures and
            includes other people jointly participating in using the services,
            including, without limitation, a user who has the right to sell
            their food dishes on Rasodaa.
          </p>
          <p className="font-bold">Content</p>
          <p>
            "Content" will include (but is not limited to) reviews, images,
            photos, audio, video, location data, nearby places, and all other
            forms of information or data. “Your content” or "Customer Content"
            means content that you upload, share, or transmit to, through, or in
            connection with the Services, such as likes, ratings, reviews,
            images, photos, messages, chat communication, profile information,
            or any other materials that you publicly display or display in your
            account profile.
          </p>
          <p>
            "Rasodaa Content" refers to content created and made available by
            Rasodaa in connection with the Services, such as visual interfaces,
            interactive features, graphics, design, compilation, computer code,
            products, software, aggregate ratings, reports, and other
            usage-related data in connection with activities associated with
            your account, as well as all other elements and components of the
            Services, excluding Your Content and Third Party Content. "Third
            Party Content" refers to content provided by parties other than
            Rasodaa or its customers and made available through the services.
          </p>
          <p className="font-bold">Chefs(s)</p>
          <p>
            "Chef" means the individual person who is listed on the Rasodaa
            Platform.
          </p>
          <div className="font-bold">III. Eligibility to use the services.</div>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              You hereby represent and warrant that you are at least eighteen
              (18) years old and fully capable of understanding and agreeing to
              the terms, conditions, obligations, affirmations, representations,
              and warranties set forth in these terms.
            </li>
            <li>
              Observance of laws When you access and use the services, you are
              in compliance with all laws and regulations in the country in
              which you live. You agree to use the services only in accordance
              with these terms and applicable law, and in a manner that does not
              infringe on our or any third party(ies) legal rights.
            </li>
          </ul>
          <div className="font-bold">IV. Changes to the terms</div>
          <p>
            Rasodaa reserves the right to modify, amend, change, or update these
            terms at any time. You are responsible for regularly reviewing these
            terms and ensuring that they are still in effect. Your continued use
            of the Rasodaa Platform following any such amendment or change in
            the terms will be deemed as your express acceptance of such
            amended/changed terms, and you agree to be bound by such
            changed/amended terms.
          </p>
          <div className="font-bold">V. Translation of the terms</div>
          <p>
            Rasodaa may provide a translation of the terms from English into
            other languages. You understand and agree that any translation of
            the terms into other languages is for your convenience only, and
            that the English version will govern the terms of your relationship
            with Rasodaa. Furthermore, if there are any discrepancies between
            the English and translated versions of the terms, the English
            version will take precedence over the others.
          </p>
          <div className="font-bold">
            VI. Provision of the services being offered by Rasodaa
          </div>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              Rasodaa is constantly evolving in order to provide its customers
              with the best possible experience and information. You acknowledge
              and agree that the form and nature of the services provided by
              Rasodaa may necessitate certain changes. As a result, Rasodaa
              reserves the right to suspend/cancel or discontinue any or all
              products or services at any time without notice, as well as to
              make modifications and alterations to any or all of the site's
              content, products, and services without prior notice.
            </li>
            <li>
              We, the software or the software application store that makes the
              software available for download may include functionality to
              automatically check for updates or upgrades to the software.
              Unless your device, its settings, or computer software does not
              permit transmission or use of upgrades or updates, you agree that
              we, or the applicable software or software application store, may
              provide notice to you of the availability of such upgrades or
              updates and automatically push such an upgrade or update to your
              device or computer from time-to-time. You may be required to
              install certain upgrades or updates to the software in order to
              continue to access or use the Services, or portions thereof
              (including upgrades or updates designed to correct issues with the
              Services). Any updates or upgrades provided to you by us under the
              Terms shall be considered part of the Services.
            </li>
            <li>
              You acknowledge and agree that if Rasodaa disables your account,
              you may be unable to access the services, your account details, or
              any files or other content in your account.
            </li>
            <li>
              You acknowledge and agree that, while Rasodaa may not have set a
              fixed upper limit on the number of transmissions you may send or
              receive through the services at this time, Rasodaa may do so at
              any time, at its sole discretion.
            </li>
            <li>
              In order to constantly improve the Rasodaa Platform and Services,
              we conduct research and experiments on various aspects of the
              services and offerings, such as our apps, websites, user
              interfaces, and promotional campaigns. As a result, at any given
              time, some customers may experience features differently than
              others. This will be used to make the Rasodaa Platform better,
              more convenient, and easier to use, as well as to improve the
              customer experience, improve the safety and security of our
              services and offerings, and develop new services and features.
            </li>
            <li>
              By using Rasodaa's services, you agree to the following
              disclaimers:
              <ul className="ml-10" style={{ listStyleType: "disc" }}>
                <li>
                  The content on these services is provided solely for
                  informational purposes. Rasodaa disclaims any liability for
                  information that has become outdated since the last time it
                  was updated. Rasodaa reserves the right, at any time and
                  without prior notice, to make changes and corrections to any
                  part of the content on these services. Rasodaa makes no
                  guarantees about the quality of the goods, the prices listed
                  in the menus or the availability of all menu items at the
                  chef’s end. Unless stated otherwise, all pictures and
                  information contained on these services are believed to be
                  owned by or licensed to Rasodaa. Please email a takedown
                  request (by using the "Contact Us" link on the home page) to
                  the webmaster if you are the copyright owner of any content on
                  these services and you think the use of the above material
                  violates your copyright in any way. Please indicate the exact
                  URL of the webpage in your request. All images shown here have
                  been digitized by Rasodaa. No other party is authorized to
                  reproduce or republish these digital versions in any format
                  whatsoever without the prior written permission of Rasodaa.
                </li>
                <li>
                  Any certification, license, or permits ("certification") or
                  information with regard to such certification that may be
                  displayed on the Chef's listing page on the Rasodaa Platform
                  is for informational purposes only. Such Certification is
                  displayed by Rasodaa on an 'as available' basis that is
                  provided to Rasodaa by the Chef(s)/Merchant(s). Rasodaa does
                  not make any warranties about the validity, authenticity,
                  reliability, and accuracy of such certifications or any
                  information displayed in this regard. Any reliance by a
                  customer on the certification or information thereto shall be
                  strictly at such customer's own risk, and Rasodaa shall have
                  no liability whatsoever for any losses or damages resulting
                  from the use of this information, or for any inaccuracy,
                  invalidity, or discrepancy in the certification, or for the
                  Chef/non-compliance Merchant's with any applicable local laws
                  or regulations.
                </li>
              </ul>
            </li>
            <li>
              Rasodaa reserves the right to charge a subscription, membership,
              and/or convenience fee to a customer at any time in the future by
              providing reasonable prior notice in relation to any product,
              service, or other aspect of the Rasodaa Platform.
            </li>
            <li>
              Rasodaa may introduce referral and/or incentive-based programs for
              its customers from time to time (Program). These programs may be
              governed by their respective terms and conditions. Customers who
              participate in the program agree to the program's terms and
              conditions as well as the Rasodaa Platform terms. Furthermore,
              Rasodaa reserves the right to terminate / suspend the customer's
              account and/or credits / points earned, as well as the customer's
              participation in the program, if Rasodaa determines in its sole
              discretion that the customer has violated the program's rules
              and/or has been involved in activities that are in violation of
              the Program terms and/or Rasodaa Platform terms or has engaged in
              fraudulent / unlawful activities. Rasodaa also reserves the right
              to change, cancel, or suspend this offer at any time and
              discontinue its program without notice to the customer.
            </li>
          </ul>
          <br />
          <h2 className="font-bold text-2xl">
            VII. Use of services by you or Customer
          </h2>
          <br />
          <p className="font-bold">
            1. Rasodaa Customer Account Including 'SELL ON RASODAA' Access
          </p>
          <p>
            a. You must create an account in order to use some of the services'
            features, including, but not limited to, the "SELL ON RASODAA"
            Services. Our Privacy Policy governs the use of any personal
            information you provide to us during the account creation process.
            You are solely responsible for maintaining the confidentiality and
            security of your account, all changes and updates submitted through
            your account, and all activities that occur in connection with your
            account.
          </p>
          <p>
            b. You may also be able to register to use the Services by logging
            in with your credentials from third-party social networking sites
            (e.g., Facebook). You confirm that you are the owner of any such
            social media account and that you have the authority to provide us
            with your social media login information. You give us permission to
            collect your authentication information, as well as any other
            information available on or through your social media account, in
            accordance with your applicable settings and instructions.
          </p>
          <p>
            c. When you create an account and/or claim your business' listing,
            you represent to us that all information provided to us in such a
            process is true, accurate, and correct, and that you will update
            your information as needed to keep it accurate. You may not
            impersonate another person, create or use an account for someone
            other than yourself, provide an email address other than your own,
            create multiple accounts unless otherwise authorized by us, or
            provide or use false information in order to become a chef on
            Rasodaa. You acknowledge that any false information may result in
            substantial economic damages and losses for Rasodaa or third
            parties, for which you may be held liable and accountable.
          </p>
          <p>
            d. You are also accountable for all activities that take place in
            your account. You agree to notify us immediately of any unauthorized
            use of your account so that we can take appropriate corrective
            action. You also agree that you will not allow any third party to
            use your Rasodaa account for any purpose, and that you will be held
            responsible for any such unauthorized access.
          </p>
          <p>
            e. By registering for an account, you agree to receive certain
            communications related to the Rasodaa Platform or Services. For
            example, other customers may leave comments on your account or
            follow the activity on your account. You can manage your preferences
            for non-essential communications through your account settings.
          </p>
          <br />
          <p className="font-bold">2. Others Terms</p>
          <p>
            a. We provide value-added "Contact us" services via our phone lines
            to connect you to specific chefs. We record all information related
            to this call, including voice recordings of your and the chefs'
            conversations (for internal billing tracking and chef-side customer
            service improvement). When you use the Contact Us services on the
            Rasodaa Platform via the Rasodaa provided phone lines, you expressly
            agree and permit Rasodaa to record all of this information.
          </p>
          <p>
            b. You agree to use the services only for purposes that are
            permitted by (a) the terms and (b) any applicable law, regulation,
            or generally accepted practices or guidelines in the relevant
            jurisdictions.
          </p>
          <p>
            c. You agree to use the data owned by Rasodaa (as available on the
            services or through any other means like API etc.) only for personal
            use/purposes and not for any commercial use.
          </p>
          <p>
            d. Unless expressly permitted by a separate agreement with Rasodaa,
            you agree not to access (or attempt to access) any of the services
            through any means other than the interface provided by Rasodaa. You
            expressly agree not to access (or attempt to access) any of the
            Services through any automated means (including the use of scripts
            or web crawlers) and to abide by any robots.txt file present on the
            Services.
          </p>
          <p>
            e. You agree to refrain from engaging in any activity that
            interferes with or disrupts the Services (or the servers and
            networks which are connected to the Services). You agree not to
            delete or change any material or information posted by another
            Customer(s), and you agree not to spam, which includes unsolicited
            emailing, posting, or messaging.
          </p>
          <br />
          <h2 className="font-bold">VIII. Content</h2>
          <br />
          <p className="font-bold">
            1. Ownership of Rasodaa Content and Proprietary Rights
          </p>
          <p>
            a. We are the sole and exclusive owner of the services and our
            content. We also own the worldwide copyrights, trademarks, service
            marks, logos, trade names, trade dress, and other intellectual and
            proprietary rights (the "IP Rights") associated with the Services
            and Rasodaa Content, which may be protected by copyright, patent,
            trademark, and other applicable intellectual property and
            proprietary rights and laws. You acknowledge that the services
            contain original works that have been developed, compiled, prepared,
            revised, selected, and arranged by us and others using methods and
            standards of judgment developed and applied with significant time,
            effort, and money, and that they constitute valuable intellectual
            property of us and others. You also acknowledge that the services
            may include information which is designated as confidential by
            Rasodaa and that you shall not disclose such information without
            Rasodaa's prior written consent.
          </p>
          <p>
            b. You agree to protect Rasodaa's proprietary rights, as well as the
            proprietary rights of all others who have rights in the Services,
            during and after the term of this agreement, and to comply with all
            reasonable written requests made by us or our content suppliers and
            licensors, or otherwise to protect their and others' contractual,
            statutory, and common law rights in the Services. You acknowledge
            and agree that Rasodaa (or Rasodaa's licensors) owns all legal
            rights, title, and interest in and to the Services, including any
            intellectual property rights (IP Rights) that exist in the Services
            (whether those rights happen to be registered or not, and wherever
            in the world those rights may exist). You further Rasodaa may
            contain information that is designated as confidential by Rasodaa
            and you shall not disclose such information without Rasodaa's prior
            written consent. Unless you have agreed otherwise in writing with
            Rasodaa, nothing in the terms gives you a right to use any of
            Rasodaa's trade names, trademarks, service marks, logos, domain
            names, or other distinctive brand features.
          </p>
          <p>
            c. You agree not to use framing techniques to enclose any Rasodaa
            trademark, logo, or other proprietary information; or to remove,
            conceal, or obliterate any copyright or other proprietary notice or
            source identifier, including any proprietary mark's size, color,
            location, or style(s). Any violation will result in appropriate
            legal proceedings being initiated against you in an appropriate
            forum in order to seek all available/possible remedies under the
            laws of the country of violation. You may not modify, reproduce,
            publicly display, or exploit any of Rasodaa's content in any way
            unless expressly authorized by Rasodaa.
          </p>
          <p>
            d. We make no warranty or representation that your use of materials
            displayed on the Services will not infringe third-party rights not
            owned or affiliated with us, to the fullest extent permitted by
            applicable law. You agree to immediately notify us if you become
            aware of any claim that the services infringe upon any copyright,
            trademark, or other contractual, intellectual, statutory, or common
            law rights by following the instructions in section XVI below.
          </p>
          <br />
          <p className="font-bold">2. Your Licence to Rasodaa Content</p>
          <p>
            a. We grant you a personal, limited, non-exclusive, and
            non-transferable license to access and use the Services only in
            accordance with these Terms. You agree not to use the services for
            any illegal purpose or in any manner inconsistent with these terms.
            The information made available through the services may only be used
            for personal, non-commercial purposes. You agree not to use, copy,
            display, distribute, modify, broadcast, translate, reproduce,
            reformat, incorporate into advertisements and other works, sell,
            promote, create derivative works, or otherwise exploit or allow
            others to exploit any Rasodaa Content, in whole or in part, unless
            expressly authorized by us. Except for the expressly granted rights,
            we do not grant you any other express or implied rights in writing
            license to the Services, Rasodaa Content or our IP Rights.
          </p>
          <p>
            b. Depending on the circumstances, any violation of the license
            provisions in this section by you may result in the immediate
            termination of your right to use the services, as well as potential
            liability for infringement of copyright and other IP rights.
          </p>
          <br />
          <p className="font-bold">
            3. Rasodaa Licence to Your or Customer's Content
          </p>
          <p>
            In exchange for using the Rasodaa Platform and submitting your
            content, you hereby irrevocably grant Rasodaa a perpetual,
            irrevocable, worldwide, non-exclusive, fully paid and royalty-free,
            assignable, sub-licensable, and transferable license and right to
            use your content (including content shared by any chef) and all
            intellectual property rights therein for any purpose, including API
            partnerships with third parties. We mean analyze, promote,
            commercialize, create derivative works, and, in the case of
            third-party services, allow their users and others to do the same.
            You grant us permission to use your submitted name or username in
            connection with your content. You irrevocably waive, and cause to be
            waived, any claims and assertions of moral rights or attribution
            made against Rasodaa or its Customers, any third-party services and
            their users based on Your Content.
          </p>
          <br />
          <p className="font-bold">
            4. Representations Regarding Your or Customer Content
          </p>
          <p>
            a. You are solely accountable for your content. You represent and
            warrant that you are the sole author of, own, or otherwise control
            all of the rights to Your Content, or that you have received
            explicit permission from the rights holder to submit Your Content;
            Your Content was not copied from or based in whole or in part on any
            other content, work, or website; Your Content was not submitted
            using any automated process such as a script bot; and use of Your
            Content by us, third party services, and our and any third party
            Your Content is truthful and accurate; and Your Content does not
            violate the Guidelines and Policies or any applicable laws.
          </p>
          <p>
            b. If Your Content is a review, you represent and warrant that you
            are the sole author of that review; that the review accurately
            reflects an actual dining experience that you had; that you were not
            paid or otherwise compensated for authoring or posting the review;
            and that you had no financial, competitive, or other personal
            incentive to author or post a review that was not a fair expression
            of your honest opinion.
          </p>
          <p>
            c. You bear all risks associated with Your Content, including
            anyone's reliance on its quality, accuracy, or dependability, or any
            disclosure by you of personally identifiable information in Your
            Content. While we reserve the right to remove Content, we have no
            control over our Customers' actions or Content and cannot guarantee
            the accuracy, integrity, or quality of any Content. You acknowledge
            and agree that any and all liability arising from Customer-posted
            Content is the sole responsibility of the Customer who posted the
            content, not Rasodaa.
          </p>
          <br />
          <p className="font-bold">5. Content Removal</p>
          <p>
            We reserve the right, at any time and without prior notice, to
            remove, block, or disable access to any Content that we deem
            objectionable, in violation of the Terms, or otherwise harmful to
            the Services or our Customers. Subject to applicable law, we are
            under no obligation to return any of Your Content to you.
            Furthermore, the Chef reserves the right, at its sole discretion, to
            remove any images or pictures that are part of Customer Content from
            the chef's listing page.
          </p>
          <br />
          <p className="font-bold">6. Third Party Content and Links</p>
          <p>
            a. Some of the content available via the Services may include or
            link to third-party materials, such as third-party reservation
            services or online food delivery/ordering. Please keep in mind that
            your use of such third-party services will be governed by the
            third-party's terms of service and privacy policies. Third-party
            vendors who obtain their data from public sources may provide us
            with business addresses, phone numbers, and other contact
            information.
          </p>
          <p>
            b. We have no control over, and make no representations or
            endorsements about, the accuracy, relevance, copyright compliance,
            legality, completeness, timeliness, or quality of any product,
            services, advertisements, or other content appearing in or linked to
            from the Services. We do not screen or investigate third-party
            content before or after it is included in our Services.
          </p>
          <p>
            c. We reserve the right, in our sole discretion and without
            obligation, to improve or correct any errors or omissions in any
            portion of the content accessible through the Services. We may, at
            our sole discretion and without obligation, verify any updates,
            modifications, or changes to any content accessible through the
            Services, but we shall not be liable for any delays or inaccuracies
            resulting from such updates. Rasodaa does not guarantee the
            availability of any such external sites or resources, and does not
            endorse any advertising, products, or other materials on or
            available from such web sites or resources.
          </p>
          <p>
            d. Third-party content, including content posted by our Customers,
            does not reflect our views or that of our parent, subsidiary,
            affiliate companies, branches, employees, officers, directors, or
            shareholders. In addition, none of the content available through the
            Services is endorsed or certified by the providers or licensors of
            such third-party content. We assume no responsibility or liability
            for any of Your Content or any third-party content.
          </p>
          <p>
            e. You also acknowledge and agree that Rasodaa is not liable for any
            loss or damage incurred as a result of the availability of those
            external sites or resources, or as a result of any reliance placed
            by you on the completeness, accuracy, or existence of any
            advertising, products, or other materials on, or accessible through,
            such websites or resources. We expressly disclaim any liability for
            any offensive, defamatory, illegal, invasive, unfair, or infringing
            content provided by third parties, without limiting the generality
            of the foregoing.
          </p>
          <br />
          <p className="font-bold">7. Customer Reviews</p>
          <p>
            a. Customer reviews or ratings for Chefs do not reflect Rasodaa's
            opinion. Rasodaa receives multiple reviews or ratings for Chefs from
            Customers, which reflect the Customers' opinions. It is important to
            note that each and every review posted on Rasodaa is solely the
            Customer/personal reviewer's opinion. Rasodaa is a neutral platform
            that solely facilitates communication between Customers/reviewers,
            which may include Customers or chefs' owners/representatives. The
            advertisements displayed on the Rasodaa Platform are unrelated to
            the feedback received by such advertisers.
          </p>
          <p>
            b. We are a neutral platform and do not arbitrate disputes. However,
            if someone writes a review that the chef does not believe to be
            true, the chef should contact the reviewer or post a public response
            to clear up any misunderstandings. If the Chef believes that a
            specific Customer's review violates any of Rasodaa's policies, the
            Chef may contact us at info@cosmostaker.com to bring such violation
            to our attention. Rasodaa reserves the right to remove a review at
            its sole discretion if it violates the Terms, or content guidelines
            and policies, or is otherwise harmful to the services.
          </p>
          <strong className="text-2xl">
            IX. Content guidelines and privacy policy
          </strong>
          <p className="font-bold">1. Content Guidelines</p>
          <p>
            You represent that you have read, understood and agreed to our
            Guidelines and policies related to Content
          </p>
          <p className="font-bold">2. Privacy Policy</p>
          <p>
            You represent that you have read, comprehended, and agreed to our
            Privacy Statement. Please keep in mind that we may disclose
            information about you to third parties or government authorities if
            we believe it is reasonably necessary to:
          </p>
          <ul className="list-disc pl-6">
            <li>Take action regarding suspected illegal activities;</li>
            <li>Enforce or apply our Terms and Privacy Policy;</li>
            <li>
              Comply with legal process or other government inquiry, such as a
              search warrant, subpoena, statute, judicial proceeding, or other
              legal process/notice served on us;
            </li>
            <li>Protect our rights, reputation, or property.</li>
          </ul>

          <strong className="text-2xl">X. Restrictions on use</strong>
          <p className="font-bold">
            1. Without limiting the generality of these Terms, in using the
            Services, you specifically agree not to post or transmit any content
            (including review) or engage in any activity that, in our sole
            discretion:
          </p>
          <ul className="list-disc pl-6">
            <li>Violate our Guidelines and Policies</li>
            <li>
              Is harmful, threatening, abusive, harassing, tortious, indecent,
              defamatory, discriminatory, vulgar, profane, obscene, libelous,
              hateful, or otherwise objectionable, invading another's privacy,
              relating to or encouraging money laundering or gambling
            </li>
            <li>
              Constitutes an inauthentic or knowingly erroneous review, or does
              not address the goods and services, atmosphere, or other
              attributes of the business you are reviewing.
            </li>
            <li>
              Contains material that violates the standards of good taste or the
              standards of the Services
            </li>
            <li>
              Any third-party right, including but not limited to the right of
              privacy, the right of publicity, copyright, trademark, patent,
              trade secret, or any other intellectual property or proprietary
              rights, is violated.
            </li>
            <li>
              Accuses others of illegal activity, or describes physical
              confrontations
            </li>
            <li>
              Alleges any matter related to health code violations that
              necessitate reporting to the healthcare department. More
              information about health code violations can be found in our
              Guidelines and Policies.
            </li>
            <li>
              Is illegal, or violates any federal, state, or local law or
              regulation (for example, by disclosing or trading on inside
              information in violation of securities law)
            </li>
            <li>Attempts to impersonate another person or entity</li>
            <li>
              Disguises or attempts to conceal the origin of Your Content,
              including, but not limited to: I submitting Your Content under a
              false name or under false pretences; or (ii) concealing or
              attempting to conceal the IP address from which Your Content is
              submitted
            </li>
            <li>
              Constitutes a form of deceptive advertisement or causes, or is a
              result of, a conflict of interest
            </li>
            <li>
              Is of a commercial nature, such as spam, surveys, contests,
              pyramid schemes, postings or reviews submitted or removed in
              exchange for payment, postings or reviews submitted or removed by
              or at the request of the business being reviewed, or other
              advertising materials.
            </li>
            <li>
              Asserts or implies that Your Content is in any way sponsored or
              endorsed by us
            </li>
            <li>
              Contains material that is not in English or, in the case of
              products or services provided in foreign languages, the language
              relevant to such products or services
            </li>
            <li>
              Falsely states, misrepresents, or conceals your affiliation with
              another person or entity
            </li>
            <li>
              Accesses or uses the account of another customer without
              permission
            </li>
            <li>
              Distributes computer viruses or other code, files, or programs
              that interrupt, destroy, or limit the functionality of any
              computer software or hardware or electronic communications
              equipment
            </li>
            <li>
              Interferes with, disrupts, or destroys the functionality or use of
              any features of the Services or the servers or networks connected
              to the Services
            </li>
            <li>
              "Hacks" or accesses without permission our proprietary or
              confidential records, records of another Customer, or those of
              anyone else
            </li>
            <li>
              Violates any contract or fiduciary relationship (for example, by
              disclosing proprietary or confidential information of your
              employer or client in breach of any employment, consulting, or
              non-disclosure agreement)
            </li>
            <li>
              Decompiles, reverse engineers, disassembles or otherwise attempts
              to derive source code from the Services.
            </li>
            <li>
              Removes, circumvents, disables, damages, or otherwise interferes
              with security-related features or features that impose usage
              restrictions on the Services.
            </li>
            <li>
              Violates the restrictions in any robot exclusion headers on the
              services, if any, or bypasses or circumvents other measures
              employed to prevent or limit access to the services.
            </li>
            <li>
              Collects, accesses, or stores personal information about other
              Customers of the Services;
            </li>
            <li>Is posted by a bot</li>
            <li>Harms minors in any way</li>
            <li>
              Threatens the unity, integrity, defence, security, or sovereignty
              of India or the country of use, friendly relations with foreign
              states, or public order, or causes incitement to the commission of
              any cognizable offence or prevents the investigation of any
              offence, or is insulting any other nation; ab. modifies, copies,
              scrapes or crawls, displays, publishes, licences, sells, rents,
              leases, lends, transfers, or otherwise commercialises any rights
              to the services
            </li>
            <li>Attempts to do any of the foregoing</li>
            <li>
              is patently false and untrue, and is written or published in any
              form, with the intent to mislead or harass a person, entity or
              agency for financial gain or to cause any injury to any person
            </li>
            <li>
              You acknowledge that Rasodaa has no obligation to monitor your –
              or anyone else's – access to or use of the Services for violations
              of the Terms, or to review or edit any content. However, we have
              the right to do so for the purpose of operating and improving the
              services (including without limitation for fraud prevention, risk
              assessment, investigation, and customer support purposes), to
              ensure your compliance with the terms and to comply with
              applicable law or the order or requirement of legal process, a
              court, consent decree, administrative agency, or other
              governmental body
            </li>
            <li>
              You hereby agree and assure Rasodaa that you will only use the
              Rasodaa Platform/Services for lawful purposes and will not violate
              any applicable Central, Federal State, or local government or
              international law laws, regulations, ordinances, or other such
              requirements (s). You shall not upload, post, email, transmit, or
              otherwise make available any unsolicited or unauthorised
              advertising, promotional materials, junk mail, spam mail, chain
              letters, or any other form of solicitation, encumber or suffer to
              exist any lien or security interest on the subject matter of these
              Terms, or make any representation or warranty on Rasodaa's behalf
              in any form or manner
            </li>
            <li>
              You hereby agree and guarantee that you will not use abusive and
              derogatory language and/or post any objectionable information that
              is unlawful, threatening, defamatory, or obscene while
              communicating on the Rasodaa Platform, including but not limited
              to giving cooking instructions to the Chefs, communicating with
              our support agents on chat support, or communicating with the
              Delivery Partners, through any medium. Rasodaa reserves the right
              to suspend the chat support service and/or block your access and
              usage of the Rasodaa Platform at any time, with or without notice,
              if you use abusive language and/or post objectionable information.
            </li>
            <li>
              Any Content you upload will be subject to applicable laws in India
              and the country of use, and may be disabled or investigated under
              applicable laws. Furthermore, if you are found to be in violation
              of the laws and regulations, these terms, or the Rasodaa
              Platform's privacy policy, Rasodaa reserves the right to
              immediately block your access and usage of the Rasodaa Platform,
              and Rasodaa reserves the right to immediately remove any
              non-compliant content and or comment uploaded by you, and Rasodaa
              reserves the right to take appropriate recourse to such remedies
              as would be available to it under various statutes.
            </li>
          </ul>
          <br />
          <strong>XI. Customer feedback</strong>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              If you share or send any ideas, suggestions, changes, or documents
              about Rasodaa's existing business ("Feedback"), you agree that I
              your Feedback does not contain the confidential, secretive, or
              proprietary information of third parties, and (ii) Rasodaa is
              under no obligation of confidentiality with respect to such
              Feedback, and shall be free to use the Feedback indefinitely.
              (iii) Rasodaa may have already received similar Feedback from
              another Customer, or it may be under consideration or in
              development, and (iv) By providing the Feedback, you grant us a
              binding, non-exclusive, royalty-free, perpetual, global licence to
              use, modify, develop, publish, distribute, and sublicense the
              Feedback, and you irrevocably waive any claims/assertions of any
              kind against Rasodaa and its Customers Feedback.
            </li>
            <li>
              Please provide only specific feedback on Rasodaa's existing
              products or marketing strategies; do not include any ideas that
              Rasodaa's policy will not permit it to accept or consider.
            </li>
            <li>
              Notwithstanding the above mentioned clause, Rasodaa or any of its
              employees do not accept or consider unsolicited ideas, including
              ideas for new advertising campaigns, new promotions, new or
              improved products or technologies, product enhancements,
              processes, materials, marketing plans or new product names. Please
              do not submit any unsolicited ideas, original creative artwork,
              suggestions, or other works ("Submissions") in any form to Rasodaa
              or any of its employees.
            </li>
            <li>
              The goal of this policy is to avoid misunderstandings or disputes
              when Rasodaa's products or marketing strategies appear to be
              similar to ideas submitted to Rasodaa. If, despite our request,
              you still send us your ideas, the following terms will apply to
              your Submissions, regardless of what your letter says.
            </li>
            <li>Submission Conditions</li>
            <li>
              You agree that:(1) your Submissions and their contents will
              automatically become the property of Rasodaa, without compensation
              to you; (2) Rasodaa may use or redistribute the Submissions and
              their contents for any purpose and in any way; (3) Rasodaa is
              under no obligation to review the Submission; and (4) Rasodaa is
              under no obligation to keep any Submissions confidential.
            </li>
          </ul>
          <br />
          <strong>XII. Advertising</strong>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              Some of the Services are monetized through advertising and may
              display advertisements and promotions. These advertisements may be
              tailored to the content of information stored on the Services,
              queries made via the Services, or other data. Rasodaa's manner,
              mode, and extent of advertising on the Services are subject to
              change without prior notice to you. You agree that Rasodaa may
              place such advertising on the Services in exchange for Rasodaa
              granting you access to and use of the Services
            </li>
            <li>
              A portion of the website may contain advertising information,
              promotional materials, or other material submitted to Rasodaa by
              third parties or customers. The party providing the
              information/material is solely responsible for ensuring that
              material submitted for inclusion on the Rasodaa Platform or mobile
              apps complies with applicable international and national law. Your
              correspondence or business dealings with, or participation in
              promotions sponsored by, advertisers other than Rasodaa found on
              or through the Rasodaa Platform and or mobile apps, including
              payment and delivery of related goods or services, and any other
              terms, conditions, warranties, or representations associated with
              Such dealings, are solely between you and the advertiser. Rasodaa
              will not be responsible or liable for any error or omission,
              inaccuracy in advertising material or any loss or damage of any
              sort incurred as a result of any such dealings or as a result of
              the presence of such other advertiser(s) on the Rasodaa Platform
              and mobile application.
            </li>
            <li>
              Rasodaa is not involved in any way with any information related to
              a charitable campaign (such as "Give away") sent to customers
              and/or displayed on the Rasodaa Platform where customers have the
              option to order the food for free. Rasodaa accepts no
              responsibility or liability for the accuracy, completeness,
              legality, or dependability of any information pertaining to the
              charitable campaign. Customers are advised to conduct independent
              verification before taking any action in relation to the
              Charitable Campaign information displayed for informational
              purposes only.
            </li>
          </ul>
          <br />
          <strong>
            XIII. Additional Terms and Conditions for Customers Using the
            Different Services Offered by Rasodaa:
          </strong>
          <p className="font-bold">1. ONLINE ORDERING:</p>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              Rasodaa provides online ordering services by entering into
              contractual arrangements with chef partners(“Chefs”).
            </li>
            <li>
              Customers can access the Rasodaa Platform's menu items or Products
              and place online orders through Rasodaa.
            </li>
            <li>
              Your request to order food and beverages or Products from a Chef
              on the Rasodaa Platform constitutes an unconditional and
              irrevocable authorization granted to Rasodaa to place online
              orders for food and beverages or Products on your behalf against
              the Chef(s).
            </li>
            <li>
              The Chef may deliver an order placed by you through the Rasodaa
              Platform directly, or Rasodaa may facilitate delivery through a
              third-party who may be available to provide delivery services to
              you ("Delivery Partners"). Rasodaa is simply acting as an
              intermediary between you and the Delivery Partners, or between you
              and the Chef, in both of these cases.
            </li>
            <li>
              The acceptance by a Delivery Partner of undertaking delivery of
              your order shall constitute a contract of service between you and
              the Delivery Partner under the Consumer Protection Act, 2019 or
              any successor legislations, to which Rasodaa is not a party under
              any applicable law. It is clarified that Rasodaa does not provide
              delivery or logistics services and only facilitates the delivery
              of food and beverages or Products ordered by customers through the
              Rasodaa Platform by connecting them with delivery partners or the
              Chef, as the case may be.
            </li>
            <li>
              Rasodaa shall not be liable for any acts or omissions on the part
              of the Delivery Partner or Chef, including deficiency in service,
              wrong delivery of order, time taken to deliver the order, order
              package tampering, and so on, where Rasodaa facilitates delivery
              of an order placed by you.
            </li>
            <li>
              Rasodaa, the Delivery Partner, or the Chef may charge you a
              delivery fee for delivering your order. You agree that Rasodaa is
              authorised to collect Delivery Charges on behalf of the Chef or
              the Delivery Partner for the delivery service provided by the Chef
              or the Delivery Partner, as applicable. The Delivery Charges may
              vary from order to order and are based on a number of factors,
              including but not limited to order value, distance, and time of
              day. Rasodaa will notify you of any applicable Delivery Charges,
              but you will be responsible for any Delivery Charges incurred for
              your order regardless of your awareness of such Delivery Charges.
            </li>
            <li>
              In addition to the Delivery Charges, you may be charged an amount
              towards delivery surge for delivery of your order facilitated by
              the Delivery Partner or the chef, which is determined based on
              various factors such as but not limited to distance covered, time
              taken, demand for delivery, real time analysis of traffic and
              weather conditions, seasonal peaks, or such other parameters as
              may be determined from time to time ("Delivery Surge"). You agree
              that Rasodaa is authorised to collect the Delivery Surge on behalf
              of the Chef or the Delivery Partner for the delivery service
              provided by the Chef or the Delivery Partner, as applicable.The
              Delivery Surge may vary from order to order, which may be
              determined on multiple factors which shall include but not be
              limited to Chef, order value, distance, demand during peak hours.
              Rasodaa will use reasonable efforts to inform you of the Delivery
              Surge that may apply to you, provided you will be responsible for
              the Delivery Surge incurred for your order regardless of your
              awareness of such Delivery Surge.
            </li>
            <li>
              In respect of the order placed by You, Rasodaa shall issue
              documents like order summary, tax invoices, etc. as per the
              applicable legal regulations and common business practices.
            </li>
          </ul>
          <br />
          <p className="font-bold">A. Online Ordering with Chefs:</p>
          <p>
            a. All prices listed on the Rasodaa Platform are provided by Chefs
            at the time of publication and have been placed as received from the
            Chef. While we make every effort to keep them up to date, the final
            price charged to you by the Chef at the time of delivery may change.
            In the event of a price conflict between the Rasodaa Platform and
            the price charged by the Chef, the price charged by the Chef shall
            be deemed to be the correct price, except for the Rasodaa Delivery
            Charge.
          </p>
          <p className="font-bold">B. General Terms and Conditions</p>
          <p>
            a. Rasodaa is not a manufacturer, seller or distributor of food and
            beverages or Products and merely places an order against the Chef(s)
            on behalf of the Customers pursuant to the unconditional and
            irrevocable authority granted by the Customers to Rasodaa, and
            facilitates the sale and purchase of food and beverages or Products
            between Customers and Chef(s), under the contract for sale and
            purchase of food and beverages or Products between the Customers and
            Chef(s).
          </p>
          <p>
            b. Rasodaa shall not be liable for any acts or omissions of the
            Chef(s), including deficiencies in service, incorrect order delivery
            / order mismatch, quality, incorrect pricing, insufficient quantity,
            time required to prepare or deliver the order, and so on.
          </p>
          <p>
            c. The Chef(s) shall be solely responsible for any
            warranty/guarantee of the food and beverages or Products sold to the
            Customer and in no event shall be the responsibility of Rasodaa.
          </p>
          <p>
            d. Rasodaa hereby clarifies for Customers in India that any
            violation of the applicable rules and regulations made thereunder
            shall solely rest with the sellers/brand owners, vendors, Chef(s),
            importers or manufacturers of the food products, Products, or any
            Pre Packed Goods. For the sake of clarity, Pre-Packed Goods shall
            mean food and beverage items that have been placed in a package of
            any kind in such a way that the contents cannot be changed without
            tampering with it and are ready for sale to the customer, or as may
            be defined from time to time under the Food Safety and Standards
            Act, 2006.
          </p>
          <p>
            e. Please note that some of the food and beverages or Products may
            be suitable for certain ages only. You should check the dish you are
            ordering and read its description, if provided, prior to placing
            your order. Rasodaa shall not be liable in the event the food and
            beverages or the Product ordered by You does not meet your dietary
            or any other requirements and/or restrictions.
          </p>
          <p>
            f. You will be required to provide certain details when placing an
            order, including your contact number and delivery address. You agree
            to exercise extreme caution when providing these details and warrant
            that they are accurate and complete at the time of placing an Order.
            By providing these details, you agree to Rasodaa's terms and privacy
            policies.
          </p>
          <p>
            g. You or any person instructed by you shall not resell food and
            beverages or Products purchased via the Rasodaa Platform.
          </p>
          <p>
            h. When you place your order, the total price for the food ordered,
            including Delivery Charges and other charges, will be displayed on
            the Rasodaa Platform and may be rounded up to the nearest amount.
            Customers must pay in full for any food or products ordered through
            the Rasodaa Platform.
          </p>
          <p>
            i. Any amount that may be charged to you by Rasodaa over and above
            the order value, shall be inclusive of applicable taxes.
          </p>
          <p>
            j. Delivery periods/Takeaway time quoted at the time of ordering are
            approximate only and may vary.
          </p>
          <p>
            k. Personal Promo code can only be used by You subject to such terms
            and conditions set forth by Rasodaa from time to time.
          </p>
          <p>l. Cancellation and refund policy:</p>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              i. You acknowledge that (1) your cancellation, attempted or
              purported cancellation of an order, or (2) cancellation for
              reasons not attributable to Rasodaa, such as providing incorrect
              particulars, contact number, delivery address, etc., or being
              unresponsive, not reachable, or unavailable for fulfilment of the
              services offered to you, shall constitute a breach of your
              unconditional and irrevocable authorization in favour of Rasodaa
              to place that order. In the event you commit an Authorization
              Breach, you shall be liable to pay the liquidated damages of an
              amount equivalent to the order value. You hereby authorise Rasodaa
              to deduct or collect the amount payable as liquidated damages
              through such means as Rasodaa may determine in its discretion,
              including without limitation, by deducting such amount from any
              payment made towards your next Order
            </li>
            <li>
              ii. Rasodaa may be unable to accept or cancel your order for a
              variety of reasons, including, but not limited to, technical
              errors, unavailability of the ordered item(s), or any other reason
              attributable to Rasodaa, Chef, or Delivery Partner. Rasodaa will
              not charge you a cancellation fee in such cases. If you cancel
              your order after payment has been charged and you are eligible for
              a refund of the order value or a portion of it, the amount will be
              refunded to you.
            </li>
            <li>
              iii. No replacement / refund / or any other resolution will be
              provided without Chef(s)’ permission.
            </li>
            <li>
              iv. If you have a complaint about an order, including but not
              limited to food spillage, foreign objects in food, delivery of the
              wrong order or food and beverages or Products, or poor quality,
              you must provide proof of the same before any resolution can be
              provided.
            </li>
            <li>
              v. You are not entitled to a refund if the instructions you
              provided with your order are not followed in the form and manner
              you intended. The Chef follows instructions to the best of his
              ability.
            </li>
            <li>
              vi. All refunds shall be processed in the same manner as they are
              received, unless refunds have been provided to You in the form of
              credits, refund amount will reflect in your account based on
              respective banks' policies.
            </li>
          </ul>
          <br />
          <p className="font-bold">2. FOOD HYGIENE:</p>
          <p>
            a. The Customer acknowledges that Rasodaa can merely act as a
            facilitator in the hygiene audit process and does not conduct any
            hygiene audit by itself.
          </p>
          <br />
          <strong>
            XIV. Disclaimer of warranties, limitation of liability, and
            Indemnification
          </strong>
          <br />
          <p className="font-bold">1. Disclaimer of Warranties</p>
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT RASODAA IS A PRODUCT OF COSMOSTAKER,
            HAVING REGISTERED OFFICE AT FLAT NO. 304, SAI RAM PLAZA, 63 MANGAL
            NAGAR, INDORE(M.P). THE SERVICES ARE PROVIDED "AS IS" AND "AS
            AVAILABLE" AND THAT YOUR USE OF THE SERVICES SHALL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
            COSMOSTAKER, ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS,
            DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, BRANCHES, SUBSIDIARIES,
            AND LICENSORS ("RASODAA PARTIES") DISCLAIM ALL WARRANTIES, EXPRESS
            OR IMPLIED, IN CONNECTION WITH THE SERVICES INCLUDING MOBILE APPS
            AND YOUR USE OF THEM. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
            LAW, THE COSMOSTAKER MAKE NO WARRANTIES OR REPRESENTATIONS THAT THE
            SERVICES HAVE BEEN AND WILL BE PROVIDED WITH DUE SKILL, CARE AND
            DILIGENCE OR ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES'
            CONTENT AND ASSUME NO RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES,
            OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE,
            OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
            THE SERVICES, (III) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN, (IV) ANY
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
            (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH THE SERVICES THROUGH THE ACTIONS OF ANY
            THIRD PARTY, (VI) ANY LOSS OF YOUR DATA OR CONTENT FROM THE SERVICES
            AND/OR (VII) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS
            OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
            POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SERVICES. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
            USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU
            WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
            OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY
            SUCH MATERIAL. THE COSMOSTAKER WILL NOT BE A PARTY TO OR IN ANY WAY
            BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. YOU ARE SOLELY
            RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH
            OTHER CUSTOMERS OF THE SERVICES AND WITH OTHER PERSONS WITH WHOM YOU
            COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES. NO
            ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
            COSMOSTAKER OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY
            WARRANTY NOT EXPRESSLY STATED IN THE TERMS. UNLESS YOU HAVE BEEN
            EXPRESSLY AUTHORIZED TO DO SO IN WRITING BY COSMOSTAKER, YOU AGREE
            THAT IN USING THE SERVICES, YOU WILL NOT USE ANY TRADE MARK, SERVICE
            MARK, TRADE NAME, LOGO OF ANY COMPANY OR ORGANIZATION IN A WAY THAT
            IS LIKELY OR INTENDED TO CAUSE CONFUSION ABOUT THE OWNER OR
            AUTHORIZED USER OF SUCH MARKS, NAMES OR LOGOS.
          </p>
          <br />
          <p className="font-bold">Limitation of Liability</p>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            THE COSMOSTAKER BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM ANY
            (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, AND/OR (II)
            PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
            RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES INCLUDING
            MOBILE APP, AND/OR (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
            SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN,
            AND/OR (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
            OUR SERVERS, AND/OR (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
            LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
            THIRD PARTY, AND/OR (VI) ANY LOSS OF YOUR DATA OR CONTENT FROM THE
            SERVICES, AND/OR (VII) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR
            ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF
            ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER
            LEGAL THEORY, AND WHETHER OR NOT THE COSMOSTAKER ARE ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES, AND/OR (VIII) THE DISCLOSURE OF
            INFORMATION PURSUANT TO THESE TERMS OR OUR PRIVACY POLICY, AND/OR
            (IX) YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE
            AND CONFIDENTIAL, AND/OR (X) LOSS OR DAMAGE WHICH MAY BE INCURRED BY
            YOU, INCLUDING BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT OF
            RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF
            ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION
            BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS
            ON THE SERVICES, AND/OR DELAY OR FAILURE IN PERFORMANCE RESULTING
            FROM CAUSES BEYOND COSMOSTAKER'S REASONABLE CONTROL. IN NO EVENT
            SHALL THE COSMOSTAKER BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES WHATSOEVER,
            HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING BUT NOT
            LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR
            INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS
            OF DATA SUFFERED, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
            SERVICES, OR OTHER INTANGIBLE LOSS.
          </p>

          <p className="font-bold">Indemnification</p>
          <p>
            You agree to indemnify, defend, and hold harmless the COSMOSTAKER
            from and against any third-party claims, damages (actual and/or
            consequential), actions, proceedings, demands, losses, liabilities,
            costs and expenses (including reasonable legal fees) suffered or
            reasonably incurred by us as a result of, or in connection with: I
            Your Content, (ii) your unauthorised use of the Services, or
            products or services included or advertised in the Services; iii)
            your access to and use of the Services; (iv) your violation of
            another party's rights; or (v) your breach of these Terms,
            including, but not limited to, any infringement by you of any third
            party's copyright or intellectual property rights. We retain the
            exclusive right to settle, compromise, and pay any and all claims or
            causes of action brought against us without your prior consent. We
            reserve the right to assume the sole defence and control of any
            matter for which you are required to indemnify us, at your expense,
            and you agree to cooperate with our defence of these claims. You
            agree not to settle any matter in which we are named as a defendant
            and/or for which we are named as the plaintiff which you have
            indemnity obligations without our prior written consent. We will use
            reasonable efforts to notify you of any such claim, action, or
            proceeding upon becoming aware of it.
          </p>
          <br />
          <strong>XV. Termination of your access to the services</strong>
          <br />
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              You can delete your account at any time by contacting us via the
              "Contact Us" link on the mobile app and website.
            </li>
            <li>
              In our sole discretion, we may terminate your use of the Services
              and deny you access to the Services for any or no reason,
              including your: I a violation of these Terms; or (ii) a failure to
              use the Services. You acknowledge and agree that any termination
              of your access to the Services may occur without prior notice, and
              that we may immediately deactivate or delete your account and all
              related information, as well as bar any further access to your
              account or the Services.If you use the Services in violation of
              these terms, we may, in our sole discretion, retain all data
              collected from your use of the Services. Further, you agree that
              we shall not be liable to you or any third party for the
              discontinuation or termination of your access to the Services
            </li>
          </ul>

          <br />
          <strong>XVI. General terms</strong>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              Interpretation:
              <br />
              The section and subject headings in these Terms are included for
              reference only and shall not be used to interpret any provisions
              of these Terms.
            </li>
            <li>Complete Agreement and Waiver:</li>
            <li>
              3. The Terms, along with the 'Privacy Policies' and 'Guidelines
              and Policies,' constitute the entire agreement between you and us
              regarding the Services. No failure or delay on our part in
              exercising any right, power, or privilege under the Terms shall be
              construed as a waiver of such right or acceptance of any variation
              of the Terms, nor shall any single or partial exercise of any
              right, power, or privilege by either party preclude any further
              exercise of that right or the exercise of any other right, power,
              or privilege.
            </li>
            <li>
              Severability:
              <br />
              If any provision of these Terms is deemed unlawful, invalid, or
              unenforceable by a judicial court for any reason, then that
              provision shall be deemed severed from these Terms, and the
              remainder of the Terms shall continue in full force and effect.
            </li>
            <li>
              Partnership or Agency:
              <br />
              None of the provisions of these Terms shall be construed as
              establishing a partnership or agency between you and CosmostakeR,
              and you shall have no authority to bind Rasodaa in any way.
            </li>
            <li>
              Governing Law/Waiver:
              <br />
              (a) For Customers residing in India: These Terms shall be governed
              by the laws of India. The Court of Indore shall have exclusive
              jurisdiction over any dispute arising under these terms.
            </li>
            <li>
              (b) For all Customers: YOU MUST COMMENCE ANY LEGAL ACTION AGAINST
              US WITHIN ONE (1) YEAR AFTER THE ALLEGED HARM INITIALLY OCCURS.
              FAILURE TO COMMENCE THE ACTION WITHIN THAT PERIOD SHALL FOREVER
              BAR ANY CLAIMS OR CAUSES OF ACTION REGARDING THE SAME FACTS OR
              OCCURRENCE, NOTWITHSTANDING ANY STATUTE OF LIMITATIONS OR OTHER
              LAW TO THE CONTRARY. WITHIN THIS PERIOD, ANY FAILURE BY US TO
              ENFORCE OR EXERCISE ANY PROVISION OF THESE TERMS OR ANY RELATED
              RIGHT SHALL NOT CONSTITUTE A WAIVER OF THAT RIGHT OR PROVISION.
            </li>
            <li>
              Carrier Rates may Apply:
              <br />
              You may be charged by your Internet or mobile service provider if
              you access the Services through a mobile or other device, so check
              with them first if you are unsure, as you will be solely
              responsible for any such costs incurred.
            </li>
            <li>
              Linking and Framing:
              <br />
              You are not permitted to frame the Services. You may link to the
              Services, but you must acknowledge and agree not to link to any
              website that contains any inappropriate, profane, defamatory,
              infringing, obscene, indecent, or unlawful topic, name, material,
              or information, or that violates any intellectual property,
              proprietary, privacy, or publicity rights. Any violation of this
              provision may, in our sole discretion, result in the immediate
              termination of your use of and access to the Services.
            </li>
          </ul>
          <br />
          <strong>XVII. Notice of copyright infringement</strong>
          <p>
            Rasodaa will not be liable for any copyright infringement resulting
            from materials posted on or transmitted through the Rasodaa
            Platform, or items advertised on the Rasodaa Platform, by end users
            or any other third party. We respect others' intellectual property
            rights and expect those who use the Services to do the same. In
            appropriate circumstances and at our discretion, we may remove or
            disable access to material on the Services that infringes on the
            intellectual property rights of others. We may also remove or
            disable links or references to an online location that contains
            infringing material or infringing activity at our discretion.In the
            event that any Customers of the Services repeatedly infringe on
            others' copyrights, we may in our sole discretion terminate those
            individuals' rights to use the Services If you believe that your
            copyright has been or is being infringed upon by material found in
            the Services, you are required to follow the below procedure to file
            a notification:
          </p>
          <p>
            i. Identify in writing the copyrighted material that you claim has
            been infringed upon;
          </p>
          <p>
            ii.Identify in writing the material on the Services that you claim
            infringes on copyrighted material, and include enough information to
            reasonably identify the location of the alleged infringing material.
          </p>
          <p>
            iii. Include the following statement: "I have a good faith belief
            that the use of the content on the Services as described above is
            not authorized by the copyright owner, its agent, or law";
          </p>
          <p>
            iv. Include the following statement: "I swear under penalty of
            perjury that the information in my notice is accurate and that I am
            the copyright owner or have authority to act on behalf of the
            copyright owner";
          </p>
          <p>
            v. Provide your contact information including your address,
            telephone number, and e-mail address (if available);
          </p>
          <p>vi. Provide your physical or electronic signature;</p>
          <p>vii. Send us a written communication to info@cosmostaker.com</p>
          <p>
            You may be subject to liability if you knowingly make any
            misrepresentations on a take-down notice.
          </p>
          <br />
          <strong className="my-2">XVIII. Contact Us:</strong>
          <p className="font-bold">1. Details of the Company</p>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>Legal Entity Name: CosmostakeR</li>
            <li>Registration Number: INDO220718SE007880</li>
            <li>
              Registered Address: Flat No. 304, Sairam Plaza, 63 Mangal Nagar,
              Indore.
            </li>
            <li>
              Details of website and Application: https://rasodaa.in/ (“Website”)
              and “Rasodaa” application for mobile and handheld devices
            </li>
            <li>Contact Details: info@cosmostaker.com</li>
          </ul>
          <br />
          <p className="font-bold my-2">Grievance Redressal Mechanism:</p>
          <p>Customer Care Channels</p>
          <ul className="ml-10" style={{ listStyleType: "disc" }}>
            <li>
              For any order related issue, you may first reach out to us via
              support on the Rasodaa app for real time basis resolution.
            </li>
            <li>
              You may write to us at info@cosmostaker.com and we will strive to
              resolve your order related grievance within the timelines
              prescribed under applicable laws.
            </li>
            <li>
              For a complaint raised on info@cosmostaker.com , you will receive
              a unique ticket number to track the status of your complaint.
            </li>
            <li>
              In case you do not receive a satisfactory response from the
              support and info@cosmostaker.com as the case maybe, you can
              escalate the matter to our team at info@cosmostaker.com by giving
              reference of the ticket number generated from info@cosmostaker.com
              and we shall address your concern within an estimated time of
              forty-eight (48) hours.
            </li>
          </ul>
          <br />
          <p>
            Please note: Rasodaa does not solicit confidential information such
            as OTP/CVV/PIN NUMBER/Card number either through call or mail or any
            other means. Please do not reveal these details to fraudsters and
            imposters claiming to be calling on Rasodaa’s behalf. You may report
            such suspicious activities to info@cosmostaker.com.
          </p>
        </section>
      </section>
    </>
  );
};

export default MobileTermsConditions;
