const OurCulture = () => {
  return (
    <>
      <div className="text-3xl text-red-400 flex justify-center mt-10">
        Page Coming Soon
      </div>
    </>
  );
};

export default OurCulture;
